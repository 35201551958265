import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const getTermsService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getTerms);
};

const getPrivacyService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getPrivacy);
};

const getHomeGuide = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getHomeGuide);
};

const updateTermsService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateTerms, formBody);
};

const updatePrivacyService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updatePrivacy, formBody);
};

const updateHomeGuide = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateHomeGuide, formBody);
};

export {
  getHomeGuide,
  getTermsService,
  getPrivacyService,
  updateTermsService,
  updatePrivacyService,
  updateHomeGuide,
};
