import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const userService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.user, formBody);
}

const blockService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.blockUser, formBody);
}

const unBlockService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.unBlockUser, formBody);
}

const getNewsLetter = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.newsLetter)
}

const getContactUs = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.contactus)
}

const updateUserOrder = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.updateUserOrder, obj);
}

const reportedUser = (active, search) => {
  return HTTP_Request.get(BASE_URL + Endpoints.reportedUser + `?pagination=10&page=${active}&search=${search}`);
}

const getArtist = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.getArtistsList, formBody);
}

const getWriters = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.getWriterList, formBody);
}

const registerUserService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.registerArtist, formBody);
}

const deleteUserService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteUser + id);
}

const updateEmail = (obj, id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.updateEmail + id, obj);
}

export { userService, blockService, unBlockService, getNewsLetter, getContactUs, updateUserOrder, reportedUser, getArtist, getWriters, registerUserService, deleteUserService, updateEmail };
