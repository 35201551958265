import { useDropzone } from "react-dropzone";

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Container, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UploadImage, UploadedImage } from "../../../assets/index";
import Box from "@mui/material/Box";
import Tags from "../Tags";
import { toastMessage } from "../Toast";
import {
  createArtServices,
  updateArtService,
} from "../../services/ArtServices";
import ProgressBar from "../../components/progressBar";
import axios from "axios";
import Cropper from "./../Modals/Cropper";
import { getArtist } from "../../services/userService";
import Autocomplete from "./../../components/AutoComplete";
import Loader from "./../Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
  handleArt,
  selectedArt,
  editArt,
  setSelectedArt,
  setEdit,
  setEditArt,
  getArt,
}) {
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [content, setContent] = useState("");
  const [tagsGenre, setTagsGenre] = useState([]);
  const [hashTags, setHashTags] = useState([]);
  const [title, setTitle] = useState("");
  const [options, setOptions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [imagePath, setImagePath] = useState("");
  const [cropImage, setCropImage] = useState(null);
  const [allArtists, setAllArtists] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(
    selectedArt ? selectedArt?.user : null
  );
  const [loader, setLoader] = useState(false);
  const [imageForEdit, setImageForEdit] = useState(null);

  const onClose = () => {
    setHashTags([]);
    setTagsGenre([]);
    setImage({ preview: "", raw: "" });
    setTitle("");
    setContent("");
    handleClose();
    setAllArtists([]);
    setSearchText("");
    setSelectedUser(null);
    setLoader(false);
    setSelectedArt(null);
    setEditArt(false);
    setImageForEdit(null);
  };

  const AddArt = () => {
    // console.log("con", image?.preview == "")
    if (image?.preview == "" || content?.length == "" || selectedUser == null) {
      toastMessage(
        title == ""
          ? "Add Title"
          : image?.preview == ""
          ? "Please add image"
          : content.length == 0
          ? "Add description"
          : selectedUser == null
          ? "Select artist"
          : "Add all fields",
        "error"
      );
    } else {
      if (editArt) {
        let obj = {
          title: title,
          description: content,
          user_id: selectedUser?.id,
          is_approved: 1,
          category_type: 1,
        };

        if (hashTags?.length > 0) {
          // obj['tags'] = hashTags
          obj["tags"] = JSON.stringify(hashTags);
        }

        setLoader(true);
        updateArtService(obj, selectedArt?.id)
          .then(({ data }) => {
            setLoader(false);
            // setEdit(data?.data)
            getArt();
            onClose();
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        setLoader(true);
        let obj = {
          title: title,
          category_type: 1,
          image: imagePath,
          description: content,
          is_approved: 1,
          user_id: selectedUser?.id,
        };

        if (hashTags?.length > 0) {
          obj["tags"] = JSON.stringify(hashTags);
        }

        let formBody = new FormData();
        Object.keys(obj).forEach((key) => {
          formBody.append(key, obj[key]);
        });
        formBody.append("cover_image", cropImage);

        createArtServices(formBody)
          .then(({ data } = data) => {
            handleArt(data.data.art);
            onClose();
            setLoader(false);
            toastMessage(data?.message);
          })
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
            toastMessage(err?.response?.data?.message, "error");
            toastMessage(err?.response?.message, "error");
          });
      }
    }
  };
  const getAllArtists = () => {
    let obj = {
      page: 1,
      pagination: 10,
      search_text: searchText,
      filter: "all",
    };

    getArtist(obj, 1)
      .then(
        ({
          data: {
            data: {
              userList: { data },
            },
          },
        }) => {
          setAllArtists(data);
        }
      )
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getAllArtists();
  }, [open, searchText]);

  useEffect(() => {
    if (selectedArt) {
      setSelectedUser(selectedArt?.user);
      setHashTags(
        selectedArt?.tags ? selectedArt?.tags?.map((item) => item?.title) : []
      );
      setImageForEdit(selectedArt?.full_cover_image_path);
      setTitle(selectedArt?.title);
      setContent(selectedArt?.description);
      setImage({
        raw: "",
        preview: selectedArt?.full_cover_image_path,
      });
    }
  }, [selectedArt, open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            {" "}
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              {editArt ? "Edit Art listing" : "Post art"}{" "}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Box className="boxTags">
                <Tags tags={hashTags} setTags={setHashTags} text="Tags" />
              </Box>
              <UploadImageButton
                image={image}
                setImage={setImage}
                progress={progress}
                setProgress={setProgress}
                imagePath={imagePath}
                setImagePath={setImagePath}
                setCropImage={setCropImage}
                imageForEdit={imageForEdit}
              />
              <input
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className="addArtInput inputFAQ"
                placeholder="Write title ..."
                style={{ marginBottom: "2%" }}
              />
              <textarea
                style={{ resize: "none" }}
                className="inputFAQ textAreaFAQ descriptionAddArt"
                value={content}
                placeholder="Artwork Description."
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
              <br />
              <div className="tagHeading" style={{ paddingTop: "10px" }}>
                {selectedUser ? "Selected" : "Select"} artist
              </div>
              <Autocomplete
                userList={allArtists}
                setSearchText={setSearchText}
                searchText={searchText}
                setUser={setSelectedUser}
                user={selectedUser}
                editArt={editArt}
              />
              <Button
                variant="contained"
                className="customButton"
                style={{ textTransform: "none", marginTop: "5%" }}
                onClick={() => AddArt()}
              >
                {loader ? <Loader /> : editArt ? "Edit Art" : "Publish Art"}
              </Button>
            </DialogContentText>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}

function UploadImageButton({
  image,
  setImage,
  progress,
  setProgress,
  imagePath,
  setImagePath,
  setCropImage,
  imageForEdit,
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log("cropImage", cropImage);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (
            file.type === "image/svg+xml" ||
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/gif"
            //  && img.width <= 800 && img.height <= 400
          ) {
            setImage({
              preview: URL.createObjectURL(file),
              raw: file,
            });
            // handleProgressBar(file);
            setOpen(true);
          } else {
            toastMessage(
              "Please upload an SVG, PNG, JPG, or GIF file.",
              "error"
            );
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleProgressBar = (image) => {
    setProgress(0);
    const formData = new FormData();
    formData.append("image", image);

    axios
      .post("https://api.johnniemaeking.com/api/arts/upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentage);
        },
      })
      .then((response) => {
        setImagePath(response?.data?.data?.image);
      })
      .catch((error) => {
        console.error("Upload failed!", error);
      });
  };

  const handleUploadArt = (croppedImage) => {
    handleProgressBar(image?.raw);
    setCropImage(croppedImage);
  };

  return (
    <div>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {imageForEdit ? (
          <img src={imageForEdit} className="editImage" />
        ) : isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <>
            {image.preview ? (
              <>
                <div className="uploaded">
                  <div style={{ display: "flex" }}>
                    <img src={UploadedImage} height="50" />
                    <div>
                      <div>{image.raw.name}</div>
                      <div>{(image.raw.size / 1024).toFixed(0)} KB</div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ProgressBar progress={progress} />
                    <div style={{ marginLeft: "-10px" }}>{progress}%</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span className="fa-stack fa-2x mt-3 mb-2">
                  <i className="fas fa-circle fa-stack-2x" />
                  <i className="fas fa-store fa-stack-1x fa-inverse" />
                </span>
                <div className="uploadCustomButton">
                  <img src={UploadImage} />
                  <p className="uploadImageText" style={{ margin: "0px" }}>
                    <span className="uploadImageSpan">
                      Click to upload your story image
                    </span>
                  </p>
                  <p className="uploadImageText" style={{ margin: "0px" }}>
                    SVG, PNG, JPG or GIF
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <br />
      <Cropper
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        file={image.preview}
        setCropImage={setCropImage}
        handleUploadArt={handleUploadArt}
      />
    </div>
  );
}
