import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";


const getTestimonialService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getTestimonial);
}

const createTestimonialService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createTestimonial, formBody);
}

const updateTestimonialService = (obj, id) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateTestimonial + id, formBody);
}

const deleteTestimonialService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteTestimonial + id,);
}




export { getTestimonialService, createTestimonialService, deleteTestimonialService, updateTestimonialService };
