import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../shared/styles/contestDeadline.css";
import {
  getContestDescription,
  updateContestDescription,
} from "../../shared/services/contestServices";
import { toastMessage } from "../../shared/components/Toast";
import { toast } from "react-toastify";

const ContestDeadline = () => {
  const idRef = useRef(null);
  const [start, setStart] = useState("");
  const [close, setClose] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);

  const handleUpdate = () => {
    if (start !== "" && close !== "") {
      console.log("start", start);
      setLoading(true);
      let obj = {
        open_contest_deadline_description: start,
        contest_submission_deadline_description: close,
      };
      if (idRef.current !== null) {
        obj["id"] = idRef.current;
      }

      updateContestDescription(obj)
        .then(({ data: { data, status, message } }) => {
          if (status) {
            toast.success(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please fill all fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleGetDescription = () => {
    getContestDescription()
      .then(({ data: { data, message, status } }) => {
        if (status) {
          console.log("data", data);
          idRef.current = data?.contest?.id;
          setStart(data?.contest?.open_contest_deadline_description);
          setClose(data?.contest?.contest_submission_deadline_description);
        } else {
          console.log("Error", message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading2(false);
      });
  };
  useEffect(() => {
    handleGetDescription();
  }, []);
  return (
    <div>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Contest Deadline
      </Typography>
      {loading2 ? (
        <Box
          sx={{
            margin: "0 0",
          }}
        >
          <CircularProgress className="spinnerLoader" color="inherit" />
        </Box>
      ) : (
        <div className="contestContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "15px",
            }}
          >
            <label>Contest Start</label>
            <input
              id="outlined-basic"
              type={"text"}
              variant="outlined"
              className="inputSearch"
              placeholder="Enter Start Description"
              onChange={(e) => setStart(e.target.value)}
              value={start}
              autoComplete="off"
              style={{ marginBottom: "3%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "15px",
            }}
          >
            <label>Contest End</label>
            <input
              id="outlined-basic"
              type={"text"}
              variant="outlined"
              className="inputSearch"
              placeholder="Enter Close Description"
              onChange={(e) => setClose(e.target.value)}
              value={close}
              autoComplete="off"
              style={{ marginBottom: "3%" }}
            />
          </div>

          <Button
            variant="contained"
            className="btnView"
            sx={{
              width: "100%",
              background: "#101828",
              color: "white",
              "&:hover": {
                background: "#101828",
              },
            }}
            onClick={() => handleUpdate()}
            disabled={loading}
          >
            {loading ? (
              <Box
                sx={{
                  margin: "0 0",
                }}
              >
                <CircularProgress className="spinnerLoader" color="inherit" />
              </Box>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContestDeadline;
