import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Container, Input, Typography } from "@mui/material";
import Loader from "./../Loader";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirmation({
  open,
  handleClickOpen,
  handleClose,
  loader,
  check,
  selectedUser,
  handleConfirmation,
  selectedStory,
  setSelectedStory,
  selectedArt,
  isReason,
  reason,
  setReason,
}) {
  const handleYes = () => {
    if (selectedUser?.id) {
      handleConfirmation(check, selectedUser);
    } else if (selectedStory) {
      handleConfirmation(check, selectedStory);
    } else if (selectedArt) {
      handleConfirmation(check, selectedArt);
    } else {
      handleConfirmation(check);
    }
    handleClose();

    if (setSelectedStory) {
      setSelectedStory({});
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogContent>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              {check} ?!
            </Typography>
            <Typography
              sx={{
                marginTop: "5%",
              }}
            >
              Are you sure, you want to{" "}
              {check == "Select Winner"
                ? "are you want to select this user winner"
                : check?.toLowerCase()}{" "}
              {selectedUser?.first_name} ?
            </Typography>
            {isReason ? (
              <textarea
                className="textAreaFeature"
                placeholder="write something here!"
                style={{ marginTop: "10px" }}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></textarea>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              className="customButton2"
            >
              No
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleYes(true)}
              className="customButton"
            >
              {loader ? <Loader /> : "Yes"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
