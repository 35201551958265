import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import Loader from './../Loader'
// import { CrossImage } from './../../assets/images/index'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { changePasswordService } from '../../services/authService'
import { toastMessage } from './../Toast'
import CloseIcon from '@mui/icons-material/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
}) {
  const [loader, setLoader] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const handleSubmit = () => {
    if (oldPassword === '' || confirmNewPassword === '' || password === '') {
      toastMessage('Please enter all fields.', 'error')
    } else {
      let obj = {
        old_password: oldPassword,
        password: confirmNewPassword,
        password_confirmation: password,
      }
      setLoader(true)
      changePasswordService(obj)
        .then(({ data } = data) => {
          if (data.data.is_changed) {
            onClose()
          }
          setLoader(false)
          toastMessage(data?.message)
        })
        .catch((err) => {
          setLoader(false)
          toastMessage(err?.response?.data?.message, 'error')
        })
    }
  }

  const onClose = () => {
    setOldPassword('')
    setPassword('')
    setConfirmNewPassword('')
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        <Container
          sx={{
            paddingTop: '40px',
            paddingBottom: '40px',
          }}
        >
          <DialogTitle
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 32,
              lineHeight: '48px',
              letterSpacing: '-0.5px',
              color: '#11142D',
            }}
            color="text.secondary"
          >
            Change Password?
          </DialogTitle>
          <DialogContent>
            <TextFieldTitle text={'Old Password:'} />

            <TextFieldInput
              bool={true}
              setState={setOldPassword}
              state={oldPassword}
              handleSubmit={handleSubmit}
            />
            <TextFieldTitle text={'New Password:'} />

            <TextFieldInput
              bool={true}
              setState={setPassword}
              state={password}
              handleSubmit={handleSubmit}
            />
            <TextFieldTitle text={'Confirm New Password:'} />
            <TextFieldInput
              bool={true}
              setState={setConfirmNewPassword}
              state={confirmNewPassword}
              // handleSubmit={handleSubmit}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleSubmit()}
              sx={{
                backgroundColor: '#101828',
                boxShadow: 'none',
                marginTop: '7%',
                height: '56px',
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: '#101828',
                },
              }}
            >
              {loader ? <Loader /> : 'Change Password'}
            </Button>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  )
}

const TextFieldInput = ({ bool, setState, state, handleSubmit }) => {
  const [showPassword, setShowPassword] = useState(bool)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }
  return (
    <>
      <TextField
        InputProps={{
          style: {
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#F8F8F9',
            height: '56px',
          },
          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                height: '100%',
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Box>
          ),
        }}
        sx={{ width: '100%' }}
        type={!showPassword ? 'text' : 'password'}
        value={state}
        onChange={(e) => setState(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </>
  )
}

const TextFieldTitle = ({ text }) => {
  return (
    <>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '24px',
          color: '#11142D',
          marginTop: '20px',
          marginBottom: '5px',
        }}
      >
        {text}
      </Typography>
    </>
  )
}
