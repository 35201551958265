import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import ChangePassword from './../components/Modals/ChangePassword'
import LogoutIcon from '@mui/icons-material/Logout'
import Confirmation from './Modals/Confirmation'
import { logoutService } from './../services/authService'
import { logout } from '../redux/userSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toastMessage } from './Toast'

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [show, setShow] = useState(false)
  const [showCPModal, setCPShow] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClickCPShow = () => {
    setCPShow(true)
    handleClose()
  }

  const handleCloseCPShow = () => {
    setCPShow(false)
  }

  const handleClickShow = () => {
    setShow(true)
    handleClose()
  }

  const handleCloseShow = () => {
    setShow(false)
  }
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirmation = (resp) => {
    if (resp == 'Logout') {
      logoutService({})
        .then(({ data } = data) => {
          toastMessage(data?.message)
          dispatch(logout())
          navigate('/')
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '24px',
        paddingRight: '24px',
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          background: '#ececec',
          color: 'grey',
          textTransform: 'none',
          borderRadius: '12px',
        }}
      >
        <PersonOutlineIcon />
        <span style={{ marginRight: '20px', padding: '5px' }}>Admin</span>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="MenuListing"
      >
        <MenuItem
          onClick={handleClickCPShow}
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: '#808191',
            borderBottom: '1px solid rgb(228, 228, 228)',
            padding: '1rem',
          }}
        >
          <LockOpenIcon sx={{ marginRight: '10px' }} />
          Change Password
        </MenuItem>
        <MenuItem
          onClick={handleClickShow}
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: 'red',
            padding: '1rem',
          }}
        >
          <LogoutIcon style={{ marginRight: '10px' }} />
          Logout
        </MenuItem>
      </Menu>
      <Confirmation
        open={show}
        handleClickOpen={handleClickShow}
        handleClose={handleCloseShow}
        check={'Logout'}
        handleConfirmation={handleConfirmation}
      />
      <ChangePassword
        open={showCPModal}
        handleClickOpen={handleClickCPShow}
        handleClose={handleCloseCPShow}
      />
    </div>
  )
}
