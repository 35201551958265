// import React, { useState, useRef, useEffect } from 'react'
// import TextField from '@mui/material/TextField'
// import Chip from '@mui/material/Chip'
// import { Typography } from '@mui/material'

// function TagInput() {
//   const [tags, setTags] = useState([])
//   const [inputValue, setInputValue] = useState('')
//   const inputRef = useRef(null)

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value)
//   }

//   const handleInputKeyDown = (event) => {
//     if (event.key === 'Enter' && inputValue) {
//       setTags((prevTags) => [...prevTags, inputValue.trim()])
//       setInputValue('')
//     }
//   }

//   const handleDeleteTag = (index) => {
//     setTags((prevTags) => prevTags.filter((_, i) => i !== index))
//   }

//   useEffect(() => {
//     if (inputRef.current) {
//       const inputElement = inputRef.current.getElementsByTagName('input')[0]
//       inputElement.style.width = '100%'
//     }
//   }, [])

//   return (
//     <>
//       <Typography
//         sx={{
//           fontStyle: 'normal',
//           fontWeight: 500,
//           fontSize: '14px',
//           lineHeight: '20px',
//           color: '#0F1106',
//         }}
//       >
//         Genre
//       </Typography>
//       <div
//         style={{
//           width: '300px',
//           overflowX: tags.length > 2 ? 'scroll' : 'visible',
//         }}
//       >
//         <TextField
//           ref={inputRef}
//           value={inputValue}
//           onChange={handleInputChange}
//           onKeyDown={handleInputKeyDown}
//           placeholder="Enter tags"
//           fullWidth
//           InputProps={{
//             startAdornment: tags.map((tag, index) => (
//               <Chip
//                 key={tag}
//                 label={tag}
//                 onDelete={() => handleDeleteTag(index)}
//                 style={{ marginRight: '4px' }}
//               />
//             )),
//           }}
//         />
//       </div>
//     </>
//   )
// }

// export default TagInput
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { toastMessage } from './Toast'

const TagInput = ({ tags, setTags, text }) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      const newTag = inputValue.trim()
      if (newTag !== '' && !tags.includes(newTag)) {
        setTags([...tags, newTag])
        setInputValue('')
      } else if (tags.includes(newTag)) {
        toastMessage('Already added', 'error')
      }
    } else if (event.key === 'Backspace' && inputValue === '') {
      // Remove the last tag when Backspace is pressed and the input is empty
      setTags(tags.slice(0, tags.length - 1))
    }
  }

  const handleTagRemove = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag)
    setTags(updatedTags)
  }

  return (
    <div>
      <div className="tagHeading">{text}</div>
      <div className="tagsComp">
        <div style={{ display: 'flex' }}>
          {tags.map((tag) => (
            <div key={tag} className="tagsInside">
              <span className="tagText">{tag}</span>
              <CloseIcon
                onClick={() => handleTagRemove(tag)}
                sx={{ fontSize: '16px', cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>
        <input
          className="inputTags"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Enter"
        />
      </div>
    </div>
  )
}

export default TagInput
