import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Checkbox, Container, Typography } from "@mui/material";
import Loader from "../Loader";
import { toastMessage } from "../Toast";
import { makeArtFeature, removeArtFeature } from "../../services/ArtServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Cropper from "./../Modals/Cropper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClose,
  featureArt,
  setFeatureArt,
  getArtServies,
}) {
  const [loader, setLoader] = React.useState(false);
  const [valueCheckBox, setValueCheckBox] = React.useState(false);
  const [challenge, setChallenge] = React.useState("");
  const [radioSelection, setRadioSelection] = React.useState(0);
  const [openCropper, setOpenCropper] = useState(false);
  const [cropImage, setCropImage] = useState(null);

  const handleOpenCropper = () => {
    setOpenCropper(true);
  };

  const handleCloseCropper = () => {
    setOpenCropper(false);
  };

  const onClose = () => {
    setFeatureArt(null);
    handleClose();
    setValueCheckBox(false);
    setLoader(false);
    setChallenge("");
    setOpenCropper(false);
  };

  const handleSubmit = () => {
    if (featureArt?.is_approved == 0) {
      toastMessage("Art is not approved", "error");
    } else {
      let obj = { art_id: featureArt?.id, shape: Number(radioSelection) };
      if (valueCheckBox) {
        obj["challenge"] = challenge;
      }
      if (radioSelection == 1 && cropImage) {
        obj["cropped_image"] = cropImage;
      }
      if (featureArt?.is_featured == 0) {
        makeArtFeature(obj)
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            onClose();
            window.location.reload();
            getArtServies(1, "", "");
            setValueCheckBox(false);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (featureArt?.is_featured == 1) {
        removeArtFeature(obj)
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            window.location.reload();
            onClose();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const handleUploadArt = (croppedImage) => {};

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <DialogContent
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
            }}
            color="text.secondary"
          >
            Publish Artwork?
          </Typography>

          <FormControl>
            <div style={{ marginLeft: "10px", marginTop: "10px" }}>
              Select Shape
            </div>

            <RadioGroup
              sx={{ flexDirection: "row", marginLeft: "10px" }}
              aria-labelledby="demo-radio-buttons-group-label"
              value={radioSelection}
              name="radio-buttons-group"
              onChange={(e) => {
                if (e.target.value == 1) {
                  setOpenCropper(true);
                }
                setRadioSelection(e.target.value);
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Square" />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Rectangle"
              />
            </RadioGroup>
          </FormControl>

          {open && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Checkbox
                label="Writing Challenge?"
                defaultValue={valueCheckBox}
                value={valueCheckBox}
                onChange={(e) => setValueCheckBox(!valueCheckBox)}
              />
              <div>Writing challenge?</div>
            </div>
          )}

          {valueCheckBox ? (
            <textarea
              className="textAreaFeature"
              placeholder="instruction for challenge!"
              value={challenge}
              onChange={(e) => setChallenge(e.target.value)}
            ></textarea>
          ) : null}

          <Container sx={{ padding: "0px !important", display: "flex" }}>
            <Button
              onClick={onClose}
              variant="contained"
              className="customButton2"
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              className="customButton"
              onClick={() => handleSubmit()}
            >
              {loader ? <Loader /> : "Yes"}
            </Button>
          </Container>
        </DialogContent>
      </Dialog>
      <Cropper
        open={openCropper}
        handleClickOpen={handleOpenCropper}
        handleClose={handleCloseCropper}
        file={featureArt?.full_image_path}
        setCropImage={setCropImage}
        handleUploadArt={handleUploadArt}
        rectangle
      />
    </div>
  );
}
