import PieChart from "./../../shared/components/PieChart";
import React, { useEffect, useState } from "react";
import { getDataDashboard } from "../../shared/services/ArtServices";
import { Box, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import FeedIcon from "@mui/icons-material/Feed";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { logout } from "../../shared/redux/userSlice";
import { useDispatch } from "react-redux";

function Dashboard() {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const getData = () => {
    getDataDashboard()
      .then(({ data } = data) => {
        setData(data?.data);
      })
      .catch((err) => {
        if (err?.response?.status) {
          dispatch(logout())
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {Object.keys(data).length ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="gridBlocks">
            <Grid item xs={4}>
              <div className="gridStyle" style={{ background: " #ada1ff" }}>
                <div
                  className="disc"
                  style={{
                    backgroundImage: "linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)",
                    color: "#1f146e",
                  }}
                >
                  <PaletteIcon />
                </div>

                <Typography
                  sx={{
                    fontSize: "1.875rem",
                    margin: "0px",
                    fontWeight: 700,
                    lineHeight: 1.5,
                    textAlign: "center",
                    color: "rgb(6, 27, 100)",
                  }}
                >
                  {data.arts_count}
                </Typography>
                <Typography
                  sx={{
                    margin: "0px",
                    fontWeight: 600,
                    lineHeight: 1.57143,
                    fontSize: "0.875rem",
                    opacity: 0.72,
                    textAlign: "center",
                    color: "rgb(6, 27, 100)",
                  }}
                >
                  Art count
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="gridStyle" style={{ background: "rgb(255, 247, 205)" }}>
                <div
                  className="disc"
                  style={{
                    backgroundImage: "linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)",
                    color: "rgb(183, 129, 3)",
                  }}
                >
                  <FeedIcon />
                </div>

                <Typography
                  sx={{
                    fontSize: "1.875rem",
                    margin: "0px",
                    fontWeight: 700,
                    lineHeight: 1.5,
                    textAlign: "center",
                    color: "rgb(122, 79, 1)",
                  }}
                >
                  {data?.stories_count}
                </Typography>
                <Typography
                  sx={{
                    margin: "0px",
                    fontWeight: 600,
                    lineHeight: 1.57143,
                    fontSize: "0.875rem",
                    opacity: 0.72,
                    textAlign: "center",
                    color: "rgb(122, 79, 1)",
                  }}
                >
                  Stories count
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="gridStyle" style={{ background: "rgb(255, 231, 217)" }}>
                <div
                  className="disc"
                  style={{
                    backgroundImage: "linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%)",
                    color: "rgb(183, 33, 54)",
                  }}
                >
                  <EmojiEventsIcon />
                </div>

                <Typography
                  sx={{
                    fontSize: "1.875rem",
                    margin: "0px",
                    fontWeight: 700,
                    lineHeight: 1.5,
                    textAlign: "center",
                    color: "rgb(122, 12, 46)",
                  }}
                >
                  {data?.ongoing_contests_count}
                </Typography>
                <Typography
                  sx={{
                    margin: "0px",
                    fontWeight: 600,
                    lineHeight: 1.57143,
                    fontSize: "0.875rem",
                    opacity: 0.72,
                    textAlign: "center",
                    color: "rgb(122, 12, 46)",
                  }}
                >
                  Ongoing Contests
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
      <div>{Object.keys(data).length ? <PieChart stats={data} /> : ""}</div>
    </div>
  );
}

export default Dashboard;
