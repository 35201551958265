import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const getAllPaidArt = (page) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getPaidArt + `?paginate=10&&page=${page}`);
}

const getPaidArtDetail = (id) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getPaidArtDetail + id);
}

const createPaidArt = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.addPaidArt, formBody);
}

const updatePaidArt = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updatePaidArt, formBody);
}

const deletePaidArt = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deletePaidArt + id);
}


export { getAllPaidArt, getPaidArtDetail, createPaidArt, updatePaidArt, deletePaidArt };
