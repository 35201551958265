import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const getAllAnnouncement = (page) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getAnnouncement + `?pagination=10&&page=${page}`);
}

const getAllAnnouncementDetail = (id) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getAnnouncementDetail + id);
}

const createAnnouncement = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createAnnouncement, formBody);
}

const deleteAnnouncement = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteAnnouncement + id);
}

const sendAnnouncement = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.sendAnnouncement + id);
}




export { getAllAnnouncement, getAllAnnouncementDetail, createAnnouncement, deleteAnnouncement, sendAnnouncement };
