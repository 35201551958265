import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import AddFAQ from './../../shared/components/Modals/AddFAQ'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { styled } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Confirmation from './../../shared/components/Modals/Confirmation'
import { NoData } from './../../assets/index'
import { toastMessage } from '../../shared/components/Toast'
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material'
import {
  getFAQServices,
  createFAQServices,
  updateFAQServices,
  updateOrderService,
  deleteFAQService,
  updateWritingServiceOrder,
  faqCategoryService,
  getWritingServices,
  deleteWritingService,
} from '../../shared/services/FAQServices'
import Loader from '../../shared/components/Loader'

function WritingTips() {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState({})
  const [items, setItems] = useState([])
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelected({})
  }

  const getFAQs = () => {
    setLoader(true)
    getWritingServices()
      .then(({ data } = data) => {
        let temp = [...data?.data]
        for (let i = 0; i < temp.length; i++) {
          temp[i]['id'] = 'item-' + temp[i]['id']
        }
        setItems(temp)
        setLoader(false)
      })
      .catch((err) => {
        console.log('err', err)
        setLoader(false)
      })
  }

  useEffect(() => {
    getFAQs()
  }, [])

  return (
    <>
      <Box
        sx={{
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingTop: '24px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 32,
                lineHeight: '48px',
                letterSpacing: '-0.5px',
                color: '#11142D',
                marginBottom: '2%',
              }}
            >
              Writing Tips
            </Typography>
            <Typography
              sx={{
                marginTop: '-12px',
                marginBottom: '20px',
              }}
            >
              (Drag and Drop to change their position)
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                width: '100%',
                background: '#101828',
                border: '1px solid #101828',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                // borderRadius: '24px',
                '&:hover': {
                  backgroundColor: '#000000',
                },
              }}
              variant="contained"
              onClick={() => handleClickOpen()}
            >
              Add writing tip
            </Button>
          </Box>
        </Box>
        {loader ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
          >
            <Loader />
          </Box>
        ) : items.length > 0 ? (
          <DragableItems
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            setSelected={setSelected}
            items={items}
            setItems={setItems}
          />
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}

        <AddFAQ
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          selected={selected}
          items={items}
          setItems={setItems}
          categories={categories}
          check="WritingTips"
        />
      </Box>
    </>
  )
}

export default WritingTips

const DragableItems = ({
  open,
  handleClickOpen,
  handleClose,
  setSelected,
  items,
  setItems,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return

    const newItems = Array.from(items)
    const [reorderedItem] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, reorderedItem)

    setOrder(newItems)
    setItems(newItems)
  }

  const setOrder = (newItems) => {
    let temp = [...newItems]
    let ids = []
    let order = []

    temp.map((i, inx) => {
      ids.push(parseInt((i?.id).match(/\d+/)[0]))
      order.push(inx + 1)
    })

    let obj = {
      id: ids,
      order_no: order,
    }
    updateWritingServiceOrder(obj)
      .then(({ data } = data) => {})
      .catch((err) => {
        console.log('err', err)
      })
  }

  const handleDeleteItem = (id) => {
    const updatedItems = items.filter((item) => item.id !== id)
    setItems(updatedItems)
  }

  const handleEditItem = (id) => {
    // Implement your edit logic here
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <ListItem
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="dragList"
                  >
                    <CardFAQ
                      question={item.title}
                      answer={item.description}
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      item={item}
                      setSelected={setSelected}
                      items={items}
                      setItems={setItems}
                    />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const CardFAQ = ({
  question,
  answer,
  open,
  handleClickOpen,
  handleClose,
  item,
  setSelected,
  items,
  setItems,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)

  const handleClickDelete = () => {
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleUpdate = () => {
    handleClickOpen()
    setSelected(item)
  }

  const handleDelete = (id) => {
    deleteWritingService(id)
      .then(({ data } = data) => {
        if (data?.data) {
          let temp = [...items]
          let index = temp.findIndex((i) => i.id == item.id)
          temp.splice(index, 1)
          setItems(temp)
        }
        toastMessage(data?.message)
      })
      .catch(() => {})
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }))

  const handleConfirmation = (check) => {
    if (check == 'Delete') {
      let id = Number(item.id.match(/\d+/g)[0])
      handleDelete(id)
    }
  }

  return (
    <>
      <Card sx={{ marginTop: '2%', background: '#101828', color: 'white' }}>
        <CardHeader
          title={answer}
          sx={{ marginRight: '3%' }}
          action={
            <>
              <IconButton aria-label="update" onClick={() => handleUpdate()}>
                <EditIcon className="iconExpanded" />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => handleClickDelete()}
              >
                <DeleteIcon className="iconExpanded" />
              </IconButton>
              {/* <IconButton
                aria-label="expand"
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                <ExpandMoreIcon className="iconExpanded" />
              </IconButton> */}
            </>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{answer}</Typography>
          </CardContent>
        </Collapse>
        <Confirmation
          open={openDelete}
          handleClickOpen={handleClickDelete}
          handleClose={handleCloseDelete}
          handleDelete={handleDelete}
          check={'Delete'}
          handleConfirmation={handleConfirmation}
        />
      </Card>
    </>
  )
}
