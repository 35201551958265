import {
  Box,
  Button,
  FormControl,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAllFeatureArtists,
  makeFeatureWriter,
  removeFeatureWriter,
} from "../../shared/services/postServices";
import Confirmation from "../../shared/components/Modals/Confirmation";
import ViewDetail from "../../shared/components/Modals/ViewDetail";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import EditOffIcon from "@mui/icons-material/EditOff";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ViewDetailArt from "./../../shared/components/Modals/ViewDetailArt";
import MakeFeatureArt from "./../../shared/components/Modals/MakeFeatureArt";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  blockService,
  unBlockService,
} from "../../shared/services/userService";
import PermMediaIcon from "@mui/icons-material/PermMedia";

import { toastMessage } from "../../shared/components/Toast";
import Loader from "../../shared/components/Loader";
import { NoData, PlaceholderImage } from "../../assets";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { updateUserOrder } from "../../shared/services/userService";
import {
  approveArtServices,
  getAllFeatureArts,
  makeArtFeature,
  removeArtFeature,
  updateFeatureOrder,
} from "../../shared/services/ArtServices";

function FeatureArtists() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [check, setCheck] = useState("");
  const [openView, setOpenView] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState("square");

  const handleOpen = (item, check) => {
    setOpen(true);
    setSelectedUser(item);
    setCheck(check);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenView = (item) => {
    setOpenView(true);
    setSelectedUser(item);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  let tablularHead = [
    "",
    "Art Piece",
    "Art by",
    // "Approve",
    // "Challenge",
    // "Shape",
    "Details",
    "Publish Artwork",
    // "Contest",
    // 'Update',
    // "Delete",
    // "Edit",
  ];

  const handleConfirmation = (resp, selectedUser) => {
    let id = parseInt(String(selectedUser?.id).match(/\d+/)) || NaN;
    let temp = [...users];
    for (let i = 0; i < temp.length; i++) {
      temp[i]["id"] = parseInt(String(temp[i]?.id).match(/\d+/)) || NaN;
    }
    setUsers(temp);
    if (resp == "Block") {
      blockService({ id: id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = true;
          }
          setUsers(temp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (resp == "Unblock") {
      unBlockService({ id: id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = false;
          }
          setUsers(temp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (
      resp == "Make feature artist" ||
      resp == "Remove from feature artists"
    ) {
      if (selectedUser?.is_featured == 0) {
        makeFeatureWriter({ user_id: id })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            let temp = [...users];
            let index = temp.findIndex((i) => i.id == id);
            if (index > -1) {
              temp[index]["is_featured"] = 1;
            }
            setUsers(temp);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (selectedUser?.is_featured == 1) {
        removeFeatureWriter({ user_id: id })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            let temp = [...users];
            let index = temp.findIndex((i) => i?.id == id);
            if (index > -1) {
              temp.splice(index, 1);
            }
            setUsers(temp);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const getFeatureArtists = () => {
    setLoader(true);
    getAllFeatureArts(filter)
      .then(({ data: { data } }) => {
        let temp = [...data];
        for (let i = 0; i < temp.length; i++) {
          temp[i]["id"] = "item-" + temp[i]["id"];
        }
        temp.sort((a, b) => a.order - b.order);
        setLoader(false);
        setUsers(temp);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getFeatureArtists();
  }, [filter]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
              marginBottom: "2%",
            }}
          >
            Post Arrangement
          </Typography>
        </div>
        <div>
          <Filter filter={filter} setFilter={setFilter} />
        </div>
      </div>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : users.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tablularHead?.map((item) => {
                  return (
                    <>
                      <TableCell key={item?.id}>{item}</TableCell>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            {/* <UserData users={users} handleOpen={handleOpen} handleOpenView={handleOpenView} /> */}
            <DragableItems
              items={users}
              setItems={setUsers}
              handleOpen={handleOpen}
              handleOpenView={handleOpenView}
              getFeatureArt={getFeatureArtists}
            />
          </Table>
        ) : (
          <>
            <Box className="noDataBox" style={{ marginTop: "5%" }}>
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
        <Confirmation
          open={open}
          handleClickOpen={handleOpen}
          handleClose={handleClose}
          check={check}
          selectedUser={selectedUser}
          handleConfirmation={handleConfirmation}
        />
        <ViewDetail
          open={openView}
          handleClickOpen={handleOpenView}
          handleClose={handleCloseView}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </TableContainer>
    </div>
  );
}

export default FeatureArtists;

const DragableItems = ({
  items,
  setItems,
  handleOpen,
  handleOpenView,
  getFeatureArt,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setOrder(newItems);
    setItems(newItems);
  };

  const setOrder = (newItems) => {
    let temp = [...newItems];
    let ids = [];
    let order = [];

    temp.map((i, inx) => {
      ids.push(parseInt((i?.id).match(/\d+/)[0]));
      order.push(inx + 1);
    });

    let obj = {
      id: ids,
      art_order: order,
    };

    updateFeatureOrder(obj)
      .then(({ data: { data, message } }) => {
        toastMessage(message);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <TableRow
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="dragList"
                  >
                    <TableRowed
                      item={item}
                      inx={index}
                      handleOpen={handleOpen}
                      handleOpenView={handleOpenView}
                      getFeatureArt={getFeatureArt}
                    />
                    {/* <CardFAQ question={item.title} answer={item.description} open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} item={item} setSelected={setSelected} items={items} setItems={setItems} /> */}
                  </TableRow>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const TableRowed = ({
  item,
  inx,
  handleOpen,
  handleOpenView,
  getFeatureArt,
}) => {
  const [selectedArt, setSelectedArt] = useState(null);
  const [show, setShow] = useState(false);
  const [featureArt, setFeatureArt] = useState(null);
  const [openFeature, setOpenFeature] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [check, setCheck] = useState("Remove publish artwork");

  const handleShowConfirm = (item) => {
    setCheck("Remove publish artwork");
    setShowConfirm(true);
    setSelectedArt(item);
  };

  const handleCloseConfirm = () => {
    setCheck(null);
    setShowConfirm(false);
    setSelectedArt(null);
  };

  const handleOpenDetail = (item) => {
    setSelectedArt(item);
    setShow(true);
  };
  const handleCloseDetail = () => {
    setShow(false);
    setSelectedArt(null);
  };

  const handleOpenFeature = (item) => {
    setOpenFeature(true);
    setFeatureArt(item);
  };

  const handleCloseFeature = (item) => {
    setOpenFeature(false);
    setFeatureArt(null);
  };

  const handleConfirmation = (check, item) => {
    if (check == "Remove publish artwork") {
      if (selectedArt?.is_approved == 0) {
        toastMessage("Art is not approved", "error");
      } else {
        removeArtFeature({
          art_id: parseInt((selectedArt?.id).match(/\d+/)[0]),
        })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            getFeatureArt();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  return (
    <>
      <TableCell>
        <DragIndicatorIcon sx={{ marginRight: "5%", color: "grey" }} />
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              item?.full_cover_image_path
                ? item?.full_cover_image_path
                : PlaceholderImage
            }
            height="40"
            width="40"
            style={{ borderRadius: "50%", marginRight: "10px" }}
            alt="artpic"
          />
          <span className="spanElipse">
            {item?.title ? item?.title : "Not Provided"}
          </span>
        </Box>{" "}
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              item?.user?.profile_pic
                ? item?.user?.full_profile_pic
                : PlaceholderImage
            }
            height="40"
            width="40"
            style={{ borderRadius: "50%", marginRight: "10px" }}
            alt="artpic"
          />
          <span className="spanElipse">
            {item?.user?.name ? item?.user?.name : "Not Provided"}
          </span>
        </Box>
      </TableCell>
      {/* <TableCell>{item?.shape == 1 ? "Rectangle" : "Square"}</TableCell> */}
      <TableCell>
        <Button
          variant="contained"
          className="btnView"
          sx={{
            background: "#101828",
            color: "white",
            "&:hover": {
              background: "#101828",
            },
          }}
          onClick={() => handleOpenDetail(item)}
        >
          <VisibilityIcon />
        </Button>
      </TableCell>

      <TableCell>
        {item?.is_featured == 0 ? (
          <Button
            sx={{
              background: "#B3B3B3",
              color: "white",
              marginLeft: "5%",
              "&:hover": {
                background: "#dcdbdb",
              },
            }}
            // onClick={(e) => handleOpenFeature(item)}
          >
            {/* <PermMediaIcon sx={{ color: "white" }} /> */}
            Completed Contest
          </Button>
        ) : (
          <Button
            sx={{
              background: "rgba(255, 183, 190, 0.9)",
              marginLeft: "5%",
              "&:hover": {
                background: "#dcdbdb",
              },
            }}
            onClick={(e) => handleShowConfirm(item)}
          >
            <FolderDeleteIcon sx={{ color: "red" }} />
          </Button>
        )}
      </TableCell>
      {/* 
      <TableCell>
        <Button
          variant="contained"
          className="btnView"
          sx={{
            background: "#50934B",
            color: "white",
            "&:hover": {
              background: "#50934B",
            },
          }}
          // onClick={() => clickOpenContest(item)}
        >
          <EmojiEventsIcon />
        </Button>
      </TableCell>

      <TableCell>
        <Button
          sx={{
            background: " rgba(255, 183, 190, 0.9);",
            marginLeft: "5%",
          }}
          // onClick={(e) => handleConfirmOpen("Delete", item)}
        >
          <DeleteIcon sx={{ color: "red" }} />
        </Button>
      </TableCell>

      <TableCell>
        <Button
          sx={{
            background: "#acc8cc",
            marginLeft: "5%",
          }}
          // onClick={(e) => handleConfirmOpen("Delete", item)}
          // onClick={() => handleEdit(item)}
        >
          <ModeEditIcon sx={{ color: "white" }} />
        </Button>
      </TableCell> */}
      <ViewDetailArt
        open={show}
        handleClickOpen={handleOpenDetail}
        handleClose={handleCloseDetail}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        check={"Art"}
      />
      <MakeFeatureArt
        open={openFeature}
        handleClose={handleCloseFeature}
        featureArt={featureArt}
        setFeatureArt={setFeatureArt}
        getArtServies={getFeatureArt}
      />
      <Confirmation
        open={showConfirm}
        handleClickOpen={handleShowConfirm}
        handleClose={handleCloseConfirm}
        check={check}
        setCheck={setCheck}
        handleConfirmation={handleConfirmation}
        selectedArt={selectedArt}
      />
    </>
  );
};

function Filter({ filter, setFilter }) {
  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <FormControl
        sx={{
          minWidth: 230,
          marginBottom: "20px",
        }}
      >
        <Select
          value={filter}
          onChange={handleChange}
          displayEmpty
          IconComponent={FilterListIcon}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={"square"}>Square</MenuItem>
          <MenuItem value={"rectangle"}>Rectangle</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
