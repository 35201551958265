import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { PlaceholderImage } from "../../assets";
import CloseIcon from "@mui/icons-material/Close";

export default function FreeSolo({ userList, setSearchText, searchText, user, setUser, editArt }) {
  const [allUsers, setAllUsers] = useState(userList ? userList : []);

  useEffect(() => {
    setAllUsers(userList);
  }, [userList]);

  const handleInputChange = (value) => {
    setUser(value);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Stack spacing={2}>
      {user ? (
        <div className="displayAlign selectedNameAutoComplete">
          <div className="displayAlign">
            <img src={user.profile_pic ? user.full_profile_path : PlaceholderImage} style={{ marginRight: "10px", width: "30px", height: "30px", borderRadius: "50%" }} />
            <p>{user.name}</p>
          </div>
          {editArt ? "" : <div>
            <CloseIcon style={{ cursor: "pointer" }} onClick={() => setUser(null)} />
          </div>}
        </div>
      ) : (
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          options={allUsers}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            handleInputChange(newValue);
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <img src={option.profile_pic ? option.full_profile_path : PlaceholderImage} alt={option.name} style={{ marginRight: "10px", width: "30px", height: "30px", borderRadius: "50%" }} />
              {option.name}
            </li>
          )}
          renderInput={(params) => <TextField {...params} placeholder="Select User" value={searchText} onChange={(e) => handleSearch(e)} />}
        />
      )}
    </Stack>
  );
}
