import React, { useState, useEffect } from "react";
import Table from "./../../shared/components/Table";
import Data from "./../../shared/utils/fakeData.json";
import Confirmation from "./../../shared/components/Modals/Confirmation";
import ViewDetail from "./../../shared/components/Modals/ViewDetail";
import { TextField, Typography } from "@mui/material";
import {
  userService,
  blockService,
  unBlockService,
  getWriters,
  deleteUserService,
} from "../../shared/services/userService";
import Pagination from "../../shared/components/Pagination";
import { toastMessage } from "../../shared/components/Toast";
import {
  removeFeatureWriter,
  makeFeatureWriter,
} from "./../../shared/services/postServices";

function Users() {
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState("");
  const [openView, setOpenView] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const [selectedUser, setSelectedUser] = useState({});
  const [reason, setReason] = useState("");
  let tablularHead = [
    "#",
    "Name",
    "Email",
    "Platform",
    "Details",
    // "Delete User",
    "Status",
    "Delete",
    "Feature Writer",
    // "Impersonate"
  ];

  const handleClickShow = (check, item) => {
    setShow(true);
    setCheck(check);
    setSelectedUser(item);
  };

  const handleCloseShow = () => {
    setShow(false);
    // setSelectedUser({})
    setCheck("");
  };

  const handleOpenView = (item) => {
    setOpenView(true);
    setSelectedUser(item);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const getUsers = () => {
    let obj = {
      page: active,
      pagination: 10,
      search_text: searchText,
      filter: "all",
    };

    if (active == 1) {
      setLoader(true);
    }
    getWriters(obj, active)
      .then(({ data } = data) => {
        setLastPage(data?.data?.userList?.last_page);
        setUsers(data.data.userList.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   getUsers();
  // }, [searchText, active]);

  let debounceTimeout;

  useEffect(() => {
    // Clear the previous timeout if it exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to call getArt after a delay
    debounceTimeout = setTimeout(() => {
      getUsers();
    }, 300); // Adjust the delay as needed (in milliseconds)

    // Clean up the timeout on unmount
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [active, searchText]);

  const handleConfirmation = (resp, selectedUser) => {
    if (resp == "Block") {
      setStatusLoader(true);
      blockService({ id: selectedUser?.id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = true;
          }
          setUsers(temp);
          setStatusLoader(false);
        })
        .catch((err) => {
          console.log("err", err);
          setStatusLoader(false);
        });
    } else if (resp == "Unblock") {
      setStatusLoader(true);
      unBlockService({ id: selectedUser?.id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = false;
          }
          setUsers(temp);
          setStatusLoader(false);
        })
        .catch((err) => {
          console.log("err", err);
          setStatusLoader(false);
        });
    } else if (
      resp == "Make feature writer" ||
      resp == "Remove from feature writer"
    ) {
      if (selectedUser?.is_featured == 0) {
        makeFeatureWriter({ user_id: selectedUser?.id, feature_text: reason })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            let temp = [...users];
            let index = temp.findIndex((i) => i.id == selectedUser?.id);
            if (index > -1) {
              temp[index]["is_featured"] = 1;
            }
            setUsers(temp);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (selectedUser?.is_featured == 1) {
        removeFeatureWriter({ user_id: selectedUser?.id })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            let temp = [...users];
            let index = temp.findIndex((i) => i.id == selectedUser?.id);
            if (index > -1) {
              temp[index]["is_featured"] = 0;
            }
            setUsers(temp);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    } else if (resp == "Delete this user") {
      deleteUserService(selectedUser?.id)
        .then(({ data: { message } }) => {
          toastMessage(message);
          let temp = [...users];
          let index = temp.findIndex((i) => i?.id == selectedUser?.id);
          if (index > -1) {
            temp.splice(index, 1);
          }
          setUsers(temp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Writers
      </Typography>
      <input
        id="outlined-basic"
        type={!searchText.length ? "password" : "text"}
        variant="outlined"
        className="inputSearch"
        placeholder="Search by name and email"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="new-password"
        style={{ marginBottom: "3%" }}
      />
      <Table
        tablularHead={tablularHead}
        tabularArr={users}
        Confirmation={Confirmation}
        open={show}
        handleClickOpen={handleClickShow}
        handleClose={handleCloseShow}
        check={check}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        reason={reason}
        setReason={setReason}
        openView={openView}
        handleOpenView={handleOpenView}
        handleCloseView={handleCloseView}
        handleConfirmation={handleConfirmation}
        statusLoader={statusLoader}
        loader={loader}
        lastPage={lastPage}
        active={active}
        setActive={setActive}
        tableType="users"
        tableCheck={"writer"}
      />
      <ViewDetail
        open={openView}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
}

export default Users;
