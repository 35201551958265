import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Typography } from "@mui/material";
import Loader from "../../shared/components/Loader";
import { NoData } from "../../assets";
import { getContactUs } from "../../shared/services/userService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Description from "./../../shared/components/Modals/Description";

export default function ContactUs() {
  const [loader, setLoader] = useState(false);
  const [contactUsData, setContactUsData] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");

  const constactUsInfo = () => {
    setLoader(true);
    getContactUs()
      .then(({ data: { data } }) => {
        setContactUsData(data["contacts-list"]?.data);
        setLastPage(data["contacts-list"].last_page);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const handleOpenView = (item) => {
    setShow(true);
    setSelectedDescription(item?.description);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  useEffect(() => {
    constactUsInfo();
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Contact Us
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box className="tableLoader">
            <Loader />
          </Box>
        ) : contactUsData.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactUsData?.map((item, inx) => (
                <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{inx == 9 && active != 1 ? active.toString() + 0 : active != 1 ? (active - 1).toString() + (inx + 1) : inx + 1}</TableCell>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>{item?.email}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        boxShadow: "none",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleOpenView(item)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      <Description open={show} handleClickOpen={handleOpenView} handleClose={handleCloseView} selectedDescription={selectedDescription} setSelectedDescription={setSelectedDescription} />
    </Box>
  );
}
