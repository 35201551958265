function htmlToText(html) {
  // Create a temporary DOM element
  const tempElement = document.createElement("div");

  // Set the HTML content to the temporary element
  tempElement.innerHTML = html;

  // Use innerText to get the plain text content
  return tempElement.innerText.trim();
}

export { htmlToText };
