import { Paper, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Container, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NoData, PlaceholderImage } from "../../assets";
import { getAllAnnouncement, getAllAnnouncementDetail, createAnnouncement, deleteAnnouncement, sendAnnouncement } from "./../../shared/services/AnnouncementService";
import BasicPopOver from "../../shared/components/BasicPopOver";
import Loader from "../../shared/components/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Confirmation from "../../shared/components/Modals/Confirmation";
import { toastMessage } from "../../shared/components/Toast";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "../../shared/components/Pagination";

function Announcement() {
  const [loader, setLoader] = useState(false);
  const [allAnnouncement, setAllAnnouncement] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const handleShow = (item) => {
    setShowConfirm(true);
    setSelectedAnnouncement(item);
  };

  const handleCloseShow = () => {
    setShowConfirm(false);
  };

  const handleOpen = (item) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAnnouncement = () => {
    if (active == 1) {
      setLoader(true);
    }

    getAllAnnouncement(active)
      .then(
        ({
          data: {
            data: { announcements },
          },
        }) => {
          let temp = []
          announcements?.data?.map((item) => {
            item['loader'] = false
            temp.push(item)
          })
          setAllAnnouncement(temp);
          setLoader(false);
          setLastPage(announcements?.last_page);
        },
      )
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAnnouncement();
  }, [active]);

  const handleConfirmation = (check) => {
    if (check == "Delete") {
      deleteAnnouncement(selectedAnnouncement?.id)
        .then(({ data: { data, message } }) => {
          toastMessage(message, "success");
          setSelectedAnnouncement({});
          setAllAnnouncement(allAnnouncement.filter((item) => item.id !== selectedAnnouncement?.id));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleSendEmail = (item) => {
    // setEmailLoader(true);
    let temp = [...allAnnouncement]
    let index = temp?.findIndex((i) => i.id == item?.id);
    if (index > -1) {
      temp[index].loader = true
    }
    setAllAnnouncement(temp)
    sendAnnouncement(item?.id)
      .then(({ data: { data, message } }) => {
        toastMessage(message, "success");
        let temp = [...allAnnouncement]
        let index = temp?.findIndex((i) => i.id == item?.id);
        if (index > -1) {
          temp[index].loader = false
        }
        setAllAnnouncement(temp)
        // setEmailLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        // setEmailLoader(false);
        let temp = [...allAnnouncement]
        let index = temp?.findIndex((i) => i.id == item?.id);
        if (index > -1) {
          temp[index].loader = false
        }
        setAllAnnouncement(temp)
      });
  };

  const handleAdd = (obj) => {
    createAnnouncement(obj)
      .then(({ data: { data, message } }) => {
        toastMessage(message, "success");
        handleClose();
        setAllAnnouncement([data.announcement, ...allAnnouncement]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Box
      sx={{
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "24px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
              marginBottom: "2%",
            }}
          >
            Announcements{" "}
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              width: "100%",
              background: "#101828",
              border: "1px solid #101828",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              // borderRadius: '24px',
              "&:hover": {
                backgroundColor: "#000000",
              },
            }}
            variant="contained"
            onClick={() => handleOpen()}
          >
            Add More
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "5%" }}>
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
            <Loader />
          </Box>
        ) : allAnnouncement.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                {/* <TableCell>Title</TableCell> */}
                <TableCell>Description</TableCell>
                <TableCell>Delete</TableCell>
                <TableCell>Send Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAnnouncement?.map((item, inx) => (
                <TableRow key={item?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {inx + 1}
                  </TableCell>
                  {/* <TableCell>{item.title}</TableCell> */}
                  <TableCell>
                    <BasicPopOver reason={item?.description} />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Button
                      sx={{
                        background: " rgba(255, 183, 190, 0.9);",
                        marginLeft: "5%",
                      }}
                      onClick={() => handleShow(item)}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        boxShadow: "none",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleSendEmail(item)}
                    >
                      {item?.loader ? <Loader /> : <SendIcon />}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {!loader && lastPage > 1 ? <Pagination lastPage={lastPage} active={active} setActive={setActive} /> : ""}
      <Confirmation open={showConfirm} handleClickOpen={handleShow} handleClose={handleCloseShow} check={"Delete"} handleConfirmation={handleConfirmation} />
      <AddAnnouncement open={open} handleClickOpen={handleOpen} handleClose={handleClose} handleAdd={handleAdd} />
    </Box>
  );
}

export default Announcement;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddAnnouncement({ open, handleClickOpen, handleClose, handleAdd }) {
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [toggle, setToggle] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 255) {
      setDescription(inputText);
    }
  };

  const handleCreate = () => {
    if (description.length == 0) {
      toastMessage("Please enter all fields", "error");
    } else {
      let obj = {
        title: "Announcement",
        description: description,
        status: 1,
        is_emailed: toggle ? 1 : 0,
      };
      handleAdd(obj);
      onClose();
    }
  };

  const onClose = () => {
    setTitle("");
    setDescription("");
    setToggle(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" className="dialogContainer">
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              Add Announcement
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {/* Title:
              <input className="inputFAQ" value={title} onChange={(e) => setTitle(e.target.value)} /> */}
              {/* <br />
              <br /> */}
              Description:
              <textarea style={{ resize: "none" }} className="inputFAQ textAreaFAQ" value={description} onChange={handleChange}></textarea>
              <p style={{ margin: "0px" }}>{255 - description.length} words left</p>
              <div className="displayAlign" style={{ marginBottom: "-20px" }}>
                <Checkbox {...label} onChange={() => setToggle(!toggle)} value={toggle} color="default" /> Send Email to all users?
              </div>
            </DialogContentText>
            <br />
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button onClick={onClose} variant="contained" className="customButton2">
              Close
            </Button>
            <Button variant="contained" className="customButton" onClick={() => handleCreate()}>
              {loader ? <Loader /> : "Add"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
