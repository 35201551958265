import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";


const transactionListService = (paginate) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getTransactionList + `/10?page=${paginate}`);
}




export { transactionListService };
