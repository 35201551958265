import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, TextField, Typography } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PlaceholderImage, NoData } from "../../assets";
import Loader from "./Loader";
import Pagination from "./Pagination";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import EditOffIcon from "@mui/icons-material/EditOff";
import AttributionIcon from "@mui/icons-material/Attribution";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export default function BasicTable({
  tabularArr,
  tablularHead,
  Confirmation,
  open,
  handleClickOpen,
  handleClose,
  check,
  selectedUser,
  setSelectedUser,
  reason,
  setReason,
  openView,
  handleOpenView,
  handleCloseView,
  handleConfirmation,
  statusLoader,
  loader,
  lastPage,
  active,
  setActive,
  tableType,
  tableCheck,
  handleEdit,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [isReason, setIsReason] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box className="tableLoader">
            <Loader />
          </Box>
        ) : tabularArr.length ? (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tablularHead?.map((item, inx) => {
                    return <TableCell key={inx}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tabularArr?.map((item, inx) => (
                  <TableRow
                    key={item?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {inx == 9 && active != 1
                        ? active.toString() + 0
                        : active != 1
                        ? (active - 1).toString() + (inx + 1)
                        : inx + 1}
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            item?.profile_pic
                              ? item?.full_profile_path
                              : PlaceholderImage
                          }
                          height="40"
                          width="40"
                          style={{ borderRadius: "50%", marginRight: "10px" }}
                        />

                        {item?.name}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item?.email ? item?.email : "Not Provided"}
                    </TableCell>
                    <TableCell>
                      {item?.social_network == 1
                        ? "Facebook"
                        : item?.social_network == 2
                        ? "Twitter"
                        : "via email"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: "#101828",
                          color: "white",
                          "&:hover": {
                            background: "#101828",
                          },
                        }}
                        onClick={() => handleOpenView(item)}
                      >
                        <VisibilityIcon />
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <Button
                        sx={{
                          background: " rgba(255, 183, 190, 0.9);",
                          marginLeft: "5%",
                        }}
                        onClick={(e) => handleClickOpen("Delete", item)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </Button>
                    </TableCell> */}

                    <TableCell>
                      {selectedUser?.id == item?.id && statusLoader ? (
                        <div>
                          <Loader />
                        </div>
                      ) : !item?.is_blocked ? (
                        <Button
                          sx={{
                            background: " rgba(255, 183, 190, 0.9);",
                            marginLeft: "5%",
                          }}
                          onClick={() => {
                            setIsReason(false);
                            handleClickOpen("Block", item);
                          }}
                        >
                          <LockPersonIcon style={{ color: "red" }} />
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            background: " rgba(207 , 255, 204, 0.9);",
                            marginLeft: "5%",
                          }}
                          onClick={() => {
                            setIsReason(false);
                            handleClickOpen("Unblock", item);
                          }}
                        >
                          <LockOpenIcon style={{ color: "green" }} />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{
                          background: "red",
                          marginLeft: "5%",
                          "&:hover": {
                            background: "#ff7b7b",
                          },
                        }}
                        onClick={() => {
                          setIsReason(false);
                          handleClickOpen("Delete this user", item);
                        }}
                      >
                        <DeleteIcon style={{ color: "white" }} />
                      </Button>
                    </TableCell>

                    <TableCell>
                      {item?.is_featured == 0 ? (
                        <Button
                          sx={{
                            background: "rgba(207 , 255, 204, 0.9)",
                            marginLeft: "5%",
                          }}
                          onClick={(e) => {
                            setReason("");
                            setIsReason(true);
                            handleClickOpen(`Make feature ${tableCheck}`, item);
                          }}
                        >
                          <HistoryEduIcon style={{ color: "green" }} />
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            background: "rgba(255, 183, 190, 0.9)",
                            marginLeft: "5%",
                          }}
                          onClick={(e) => {
                            setIsReason(false);
                            handleClickOpen(
                              `Remove from feature ${tableCheck}`,
                              item
                            );
                          }}
                        >
                          <EditOffIcon style={{ color: "red" }} />
                        </Button>
                      )}
                    </TableCell>
                    {tableCheck == "artists" ? (
                      <TableCell>
                        <Button
                          sx={{
                            background: "#acc8cc",
                            marginLeft: "5%",
                          }}
                          // onClick={(e) => handleConfirmOpen("Delete", item)}
                          onClick={() => handleEdit(item)}
                        >
                          <ModeEditIcon sx={{ color: "white" }} />
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {lastPage > 1 ? (
              <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
        {Confirmation ? (
          <Confirmation
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            check={check}
            selectedUser={selectedUser}
            handleConfirmation={handleConfirmation}
            isReason={isReason}
            reason={reason}
            setReason={setReason}
          />
        ) : (
          ""
        )}
      </TableContainer>
    </>
  );
}
