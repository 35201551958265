import axios from "axios";
import { logout } from "../redux/userSlice";
import { store } from "./../redux/store";
import { BASE_URL } from "./../utils/constant";
export const HTTP_Request = axios.create({
  baseURL: BASE_URL,
});
const setupAxios = () => {
  HTTP_Request.interceptors.request.use(
    (config) => {
      const token = store.getState().root?.user?.user?.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};

HTTP_Request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      const { user } = store.getState().root.user;
      if (user?.token) {
        store.dispatch(logout());
        window.location.reload();
      }
    }
    return Promise.reject(err);
  }
);

export const initialConfig = (user) => {
  setupAxios();
};

initialConfig();
