import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Logo } from '../../assets'
import { useDispatch } from 'react-redux'
import { login } from './../../shared/redux/userSlice'
import { loginService } from './../../shared/services/authService'
import { toastMessage } from '../../shared/components/Toast'
import Loader from '../../shared/components/Loader'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    setLoader(true)
    loginService({ email, password })
      .then(({ data } = data) => {
        setLoader(false)
        if (data.statusCode != 400) {
          if (Object.keys(data?.data?.admin).length === 0) {
            toastMessage(data?.message, 'error')
            setLoader(false)
          } else {
            setLoader(false)
            dispatch(login(data.data?.admin))
            toastMessage(data?.message)
          }
        } else {
          toastMessage(data?.message, 'error')
        }
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.data?.message, 'error')
        // toastMessage(err.response.data.message, 'error')
      })
  }

  const changeTitle = () => {
    document.title = 'Login | JMK'
  }
  useEffect(() => {
    changeTitle()
  })

  return (
    <div className="background">
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Card className="cardLogin">
          <CardContent>
            <Container
              sx={{
                width: '90%',
                paddingTop: '36px',
                paddingBottom: '36px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '5%',
                }}
              >
                <img src={Logo} height="100" />
              </div>

              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 32,
                  lineHeight: '48px',
                  letterSpacing: '-0.5px',
                  color: '#11142D',
                }}
                color="text.secondary"
                gutterBottom
              >
                Welcome!
              </Typography>

              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#11142D',
                  marginTop: '20px',
                  marginBottom: '5px',
                }}
              >
                Email
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: 'none',
                    backgroundColor: '#F8F8F9',
                    height: '56px',
                  },
                }}
                sx={{ width: '100%' }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onKeyDown={handleKeyDown}
              />
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#11142D',
                  marginTop: '20px',
                  marginBottom: '5px',
                }}
              >
                Password
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: 'none',
                    backgroundColor: '#F8F8F9',
                    height: '56px',
                  },
                  endAdornment: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        height: '100%',
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Box>
                  ),
                }}
                sx={{ width: '100%' }}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                sx={{
                  backgroundColor: '#101828',
                  width: '100%',
                  textTransform: 'none',
                  height: '56px',
                  marginTop: '25px',
                  '&:hover': {
                    backgroundColor: '#101828',
                  },
                }}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {loader ? <Loader /> : 'Login'}
              </Button>
            </Container>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}
