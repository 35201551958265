import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Container, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PlaceholderImage } from "../../../assets";
import ViewDetailArt from "../../components/Modals/ViewDetailArt";
import Confirmation from "./Confirmation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { selectWinnerService } from "./../../services/contestServices";
import { toastMessage } from "./../Toast";
import Loader from "./../Loader";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";
import { makePostPublic, makePostUnPublic } from "../../services/postServices";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Submissions({
  open,
  handleClickOpen,
  handleClose,
  setSelectedArt,
  selectedArt,
  selectedParticipants,
  setSelectedParticipants,
  check,
}) {
  const onClose = () => {
    handleClose();
    setSelectedArt({});
    setSelectedParticipants([]);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="subMissionDialog"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              Submissions !
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TableSubmission
              selectedParticipants={selectedParticipants}
              check={check}
              setSelectedParticipants={setSelectedParticipants}
            />
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}

function TableSubmission({
  selectedParticipants,
  check,
  setSelectedParticipants,
}) {
  const [data, setData] = React.useState(selectedParticipants);
  const [showConfirm, setShowConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedStory, setSelectedStory] = useState({});
  const [loader, setLoader] = useState(false);
  const [winner, setWinner] = useState(false);

  let navigate = useNavigate();

  const handleConfirmOpen = (item) => {
    setShowConfirm(true);
    setSelectedStory(item);
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  const handleOpenView = (item) => {
    console.log("item", item);
    setShow(true);
    let temp = item?.post;
    temp["user"] = item?.user;
    setSelectedStory(temp);
    // setSelectedStory(item?.post)
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const handleConfirmation = (check, item) => {
    if (check == "Select Winner") {
      let index = selectedParticipants?.findIndex((i) => i?.id === item?.id);
      let temp = [...selectedParticipants];
      if (index > -1) {
        temp[index]["loader"] = true;
      }
      setSelectedParticipants(temp);

      setLoader(true);
      selectWinnerService(item?.id)
        .then(({ data } = data) => {
          if (data?.data?.is_winner_declared) {
            setLoader(false);
            setWinner(true);
            navigate("/completedContest");
            toastMessage(data?.message);
            if (index > -1) {
              temp[index]["loader"] = false;
            }
            setSelectedParticipants(temp);
          } else {
            toastMessage(data?.message, "error");
            setLoader(false);
            setWinner(true);
            if (index > -1) {
              temp[index]["loader"] = false;
            }
            setSelectedParticipants(temp);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          toastMessage(err?.response?.message);
          toastMessage(err?.message);
          toastMessage(err?.response?.msg);
        });
    }
  };

  const handlePublic = (item, inx) => {
    if (item?.post?.is_public == 0) {
      makePostPublic(item?.post_id)
        .then(({ data }) => {
          let temp = [...selectedParticipants];
          temp[inx].post.is_public = 1;
          setSelectedParticipants(temp);
          toastMessage(data?.message);
        })
        .catch(() => {});
    } else {
      makePostUnPublic(item?.post_id)
        .then(({ data }) => {
          let temp = [...selectedParticipants];
          temp[inx].post.is_public = 0;
          setSelectedParticipants(temp);
          toastMessage(data?.message);
        })
        .catch(() => {});
    }
  };

  return (
    <>
      {selectedParticipants?.length ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Story by</TableCell>
                <TableCell>Story detail</TableCell>
                {check == "Pending" ? <TableCell>Winner</TableCell> : ""}
                <TableCell>Public</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedParticipants?.map((item, inx) => (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{inx + 1}</TableCell>
                  <TableCell component="th" scope="item">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={
                          item?.user?.profile_pic
                            ? item?.user?.full_profile_path
                            : PlaceholderImage
                        }
                        height="40"
                        width="40"
                        style={{ borderRadius: "50%", marginRight: "10px" }}
                      />
                      {item?.user?.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleOpenView(item)}
                    >
                      <InfoIcon />
                    </Button>
                  </TableCell>
                  {check !== "Pending" ? (
                    ""
                  ) : (
                    <TableCell>
                      {" "}
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: " rgba(207 , 255, 204, 0.9);",
                          marginLeft: "5%",
                          boxShadow: "none",
                          "&:hover": {
                            background: "rgba(207 , 255, 204, 0.9)",
                          },
                        }}
                        onClick={() => handleConfirmOpen(item)}
                      >
                        {/* {loader ? (
                          <Loader />
                        ) : winner ? (
                          <EmojiEventsIcon style={{ color: 'green' }} />
                        ) : (
                          <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        )} */}
                        {item?.loader ? (
                          <Loader />
                        ) : (
                          <CheckCircleOutlineIcon style={{ color: "green" }} />
                        )}
                      </Button>
                    </TableCell>
                  )}
                  {/* {check == 'Completed' ? (
                    ''
                  ) : */}
                  <TableCell>
                    {item?.post?.is_public == 1 ? (
                      <Button
                        sx={{
                          background: " rgba(255, 183, 190, 0.9);",

                          // marginLeft: "5%",
                        }}
                        onClick={() => handlePublic(item, inx)}
                      >
                        <VisibilityOffIcon sx={{ color: "red" }} />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: " rgba(207 , 255, 204, 0.9);",
                          marginLeft: "5%",
                          boxShadow: "none",
                          "&:hover": {
                            background: "rgba(207 , 255, 204, 0.9)",
                          },
                        }}
                        onClick={() => handlePublic(item, inx)}
                      >
                        <VisibilityIcon sx={{ color: "green" }} />
                      </Button>
                    )}
                  </TableCell>
                  {/* } */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "No submission yet"
      )}
      <ViewDetailArt
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        setSelectedStory={setSelectedStory}
        selectedStory={selectedStory}
        check={"Story"}
      />
      <Confirmation
        open={showConfirm}
        handleClickOpen={handleConfirmOpen}
        handleClose={handleConfirmClose}
        check={"Select Winner"}
        // setCheck={setCheck}
        handleConfirmation={handleConfirmation}
        selectedUser={selectedStory}
        setSelectedUser={setSelectedStory}
      />
    </>
  );
}
