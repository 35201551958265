import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { reportedUser } from "./../../shared/services/userService";
import Loader from "../../shared/components/Loader";
import BasicPopover from "../../shared/components/BasicPopOver";
import { NoData, PlaceholderImage } from "../../assets";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../../shared/components/Pagination";

function ReportedUsers() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedStory, setSelectedStory] = useState();
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const handleOpenView = (item, check) => {
    setShow(true);
    setSelectedStory(item?.reportable);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const getReportedUser = () => {
    if (active == 1) {
      setLoader(true);
    }

    reportedUser(active, searchText)
      .then(({ data } = data) => {
        setData(data?.data?.reports?.data);
        setLastPage(data?.data?.reports?.last_page);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getReportedUser();
  }, [searchText, active]);

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Reported User
      </Typography>
      <input
        id="outlined-basic"
        variant="outlined"
        className="inputSearch"
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="off"
        style={{ marginBottom: "3%" }}
      />
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : data?.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Reported by</TableCell>
                <TableCell>Reported to</TableCell>
                {/* <TableCell>Reported story detail</TableCell> */}
                <TableCell>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, inx) => {
                return (
                  <>
                    <TableRow key={inx}>
                      <TableCell>
                        {" "}
                        {inx == 9 && active != 1
                          ? active.toString() + 0
                          : active != 1
                          ? (active - 1).toString() + (inx + 1)
                          : inx + 1}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={
                              item?.reported_by?.profile_pic
                                ? item?.reported_by?.full_profile_path
                                : PlaceholderImage
                            }
                            height="40"
                            width="40"
                            style={{ borderRadius: "50%", marginRight: "10px" }}
                          />

                          {item?.reported_by?.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={
                              item?.reportable?.profile_pic
                                ? item?.reportable?.full_profile_path
                                : PlaceholderImage
                            }
                            height="40"
                            width="40"
                            style={{ borderRadius: "50%", marginRight: "10px" }}
                          />

                          {item?.reportable?.name}
                        </Box>
                      </TableCell>
                      {/* <TableCell>
                        <Button
                          variant="contained"
                          className="btnView"
                          sx={{
                            background: "#101828",
                            color: "white",
                            boxShadow: "none",
                            "&:hover": {
                              background: "#101828",
                            },
                          }}
                          onClick={() => handleOpenView(item, "Story")}
                        >
                          <VisibilityIcon />
                        </Button>
                      </TableCell> */}
                      <TableCell>
                        <BasicPopover reason={item?.reason} />
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {!loader && lastPage > 1 ? (
        <Pagination lastPage={lastPage} active={active} setActive={setActive} />
      ) : (
        ""
      )}
    </Box>
  );
}

export default ReportedUsers;
