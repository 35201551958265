import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

function Loader() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress color="inherit" size={20} />
    </Box>
  )
}

export default Loader
