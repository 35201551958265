import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "../../styles/FAQ.css";
import "../../styles/main.css";
import { Box, Container, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toastMessage } from "../../components/Toast";
import { createFAQServices, updateFAQServices, createWritingServices, updateWritingServices } from "../../services/FAQServices";
import Loader from "../Loader";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClickOpen, handleClose, selected, setSelected, items, setItems, check, categories }) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [content, setContent] = useState("");
  const [selection, setSelection] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setSelection(categories[0]?.id);
  }, [categories]);

  const onClose = () => {
    handleClose();
    setAnswer("");
    setQuestion("");
    setContent("");
  };

  useEffect(() => {
    if (Object.keys(selected).length > 1) {
      if (check == "WritingTips") {
        setContent(selected?.description);
      } else {
        setAnswer(selected?.description);
        setQuestion(selected?.title);
        setSelection(Number(selected?.faq_category_id));
      }
    }
  }, [selected]);

  const handleAdd = () => {
    const obj = {
      title: check === "WritingTips" ? "Writing Tips" : question,
      description: check === "WritingTips" ? content : answer,
      ...(check !== "WritingTips" && { faq_category_id: Number(selection) }),
    };

    if (check != "WritingTips") {
      if (answer.length && question.length) {
        createFAQ();
      } else {
        toastMessage(!answer.length && !question.length ? "Please add answer and question" : !answer.length ? "Please Add answer" : !question.length ? "Please add question" : "", "error");
      }
    } else {
      if (content.length) {
        createWritingTips();
      } else {
        toastMessage("Please enter content", "error");
      }
    }

    function createFAQ() {
      setLoader(true);

      createFAQServices(obj)
        .then(({ data } = data) => {
          let temp = [...items];
          let resp = data?.data;
          resp["id"] = `item-${data?.data.id} + 1}`;
          temp.push(resp);
          setItems(temp);
          setLoader(false);
          toastMessage(data?.message);
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          setLoader(false);
        });
    }

    function createWritingTips() {
      setLoader(true);

      createWritingServices(obj)
        .then(({ data } = data) => {
          let temp = [...items];
          let resp = data?.data;
          resp["id"] = `item-${data?.data.id}`;
          temp.push(resp);
          setItems(temp);
          setLoader(false);
          toastMessage(data?.message);
          onClose();
        })
        .catch((err) => {
          toastMessage(err?.response?.message, "error");
          setLoader(false);
        });
    }
  };

  const handleUpdate = () => {
    let findIndex = items.findIndex((i) => {
      return selected?.id == i?.id;
    });

    let obj = {
      id: Number(items[findIndex]?.id.match(/\d+/g)[0]),
      title: check === "WritingTips" ? "Writing Tips" : question,
      description: check === "WritingTips" ? content : answer,
      ...(check !== "WritingTips" && { faq_category_id: Number(selection) }),
    };

    if (check != "WritingTips") {
      if (answer.length && question.length) {
        updateFAQ();
      } else {
        toastMessage(!answer.length && !question.length ? "Please add answer and question" : !answer.length ? "Please Add answer" : !question.length ? "Please add question" : "", "error");
      }
    } else {
      if (content.length) {
        updateWriting();
      } else {
        toastMessage("Please enter content", "error");
      }
    }

    function updateFAQ() {
      setLoader(true);

      updateFAQServices(obj)
        .then(({ data } = data) => {
          let temp = [...items];
          let resp = data?.data;
          resp["id"] = `item-${data?.data.id}`;
          let findIndex = temp.findIndex((i) => i.id === resp.id);
          if (findIndex > -1) {
            temp[findIndex] = resp;
          }
          setItems(temp);
          toastMessage(data?.message);
          setLoader(false);
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          setLoader(false);
        });
    }

    function updateWriting() {
      setLoader(true);

      updateWritingServices(obj)
        .then(({ data } = data) => {
          let temp = [...items];
          let resp = data?.data;
          resp["id"] = `item-${data?.data.id}`;
          let findIndex = temp.findIndex((i) => i.id === resp.id);
          if (findIndex > -1) {
            temp[findIndex] = resp;
          }
          setItems(temp);
          toastMessage(data?.message);
          setLoader(false);
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          setLoader(false);
        });
    }
    // } else {
    //   toastMessage(
    //     !answer.length && !question.length
    //       ? 'Please add answer and question'
    //       : !answer.length
    //       ? 'Please Add answer'
    //       : !question.length
    //       ? 'Please add question'
    //       : '',
    //     'error',
    //   )
    // }
  };

  const handleChange = (e) => {
    const inputText = e.target.value;
    // if (inputText.length <= 255) {
    setContent(inputText);
    // }
  };

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" className="dialogContainer">
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
            }}
            color="text.secondary"
          >
            Add {check == "WritingTips" ? "Writing Tip!" : "FAQ!"}
          </DialogTitle>
          {check == "WritingTips" ? (
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Content:
                <textarea className="inputFAQ textAreaFAQ" style={{ resize: "none" }} value={content} onChange={handleChange}></textarea>
                <p style={{ margin: "0px" }}>{content.length} words</p>
              </DialogContentText>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Question:
                <input className="inputFAQ" value={question} onChange={(e) => setQuestion(e.target.value)} />
                <br />
                <br />
                Answer:
                <textarea className="inputFAQ textAreaFAQ" value={answer} onChange={(e) => setAnswer(e.target.value)}></textarea>
              </DialogContentText>
              <br />
              <DialogContentText>
                FAQ Category:
                <select className="selection" value={selection} onChange={(e) => setSelection(e.target.value)}>
                  {categories?.map((option) => {
                    return <option value={option.id}>{option.name}</option>;
                  })}
                </select>
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button onClick={onClose} variant="contained" className="customButton2">
              Close
            </Button>
            <Button
              variant="contained"
              className="customButton"
              onClick={() => {
                selected && Object.keys(selected).length ? handleUpdate() : handleAdd();
              }}
            >
              {loader ? <Loader /> : selected && Object.keys(selected).length ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
