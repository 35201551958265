import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { publicRoutes, privateRoutes } from "./allRoutes";
import Layout from './Layout';
import { useSelector } from "react-redux";
import Login from "./../pages/auth/Login"
import ErrorPage from "./../pages/home/Error"



function AllRoutes() {

  const user = useSelector((state) => state.root.user.user)
  const location = useLocation();



  return (
    <>
      <Routes>
        {user ?
          privateRoutes.map((item) => {
            return (
              <Route
                key={item.path}
                path={item.path}
                exact={true}
                element={<Layout {...item} />}
              />
            )
          })
          :
          publicRoutes.map((item) => {
            return (
              <Route
                key={item.path}
                path={item.path}
                exact={true}
                element={<Login />}
              />
            )
          })
        }
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

    </>
  )
}

export default AllRoutes

