import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const getArtServies = (paginate, filter, search) => {
  return HTTP_Request.get(
    BASE_URL +
      Endpoints.artList +
      `/10?filters=${filter}&page=${paginate}&search=${search}`
  );
};

const createArtServices = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.createArt, obj);
};

const viewArtService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.viewArt);
};

const updateArtSerives = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateArt, formBody);
};

const deleteArtServices = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteArt + id);
};

const approveArtServices = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.approveArt + id + "/approve");
};

const ReportServices = (entity, search, pagination) => {
  return HTTP_Request.get(
    BASE_URL +
      Endpoints.reportList +
      `?pagination=10&page=${pagination}&entity_type=${entity}&search=${search}`
  );
};

const getDataDashboard = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.dashboard);
};

// makeArtFeature: "/make-art-featured",
//   removeArtFeature: "/make-art-unfeatured"

const makeArtFeature = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.makeArtFeature, formBody);
};

const removeArtFeature = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.removeArtFeature, obj);
};

const getAllFeatureArts = (filter) => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.getFeatureArts + `?filter=${filter}`
  );
};

const updateArtService = (obj, id) => {
  // let formBody = new FormData();
  // Object.keys(obj).forEach((key) => {
  //   formBody.append(key, obj[key]);
  // });
  return HTTP_Request.post(BASE_URL + Endpoints.updateArt + id, obj);
};

const updateFeatureOrder = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.updateFeatureArtOrder, obj);
};

const createBlob = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.createBlob, obj);
};

export {
  getArtServies,
  createArtServices,
  viewArtService,
  updateArtSerives,
  deleteArtServices,
  approveArtServices,
  ReportServices,
  getDataDashboard,
  makeArtFeature,
  removeArtFeature,
  updateArtService,
  getAllFeatureArts,
  updateFeatureOrder,
  createBlob,
};
