import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Placeholder, NoData, PlaceholderImage } from "../../assets";
import { Box, Button, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaletteIcon from "@mui/icons-material/Palette";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ViewDetailArt from "../../shared/components/Modals/ViewDetailArt";
import Confirmation from "../../shared/components/Modals/Confirmation";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import {
  getPostsServices,
  deletePostServices,
  approvePostServices,
  getAllPostsServices,
} from "../../shared/services/postServices";
import Loader from "../../shared/components/Loader";
import { toastMessage } from "./../../shared/components/Toast";
import Pagination from "../../shared/components/Pagination";
import moment from "moment";

export default function ApproveStory() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedArt, setSelectedArt] = useState({});
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState("");
  const [selectedStory, setSelectedStory] = useState({});
  const [confirmCheck, setConfirmCheck] = useState("");
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [allData, setAllData] = useState([]);

  const getAllPosts = () => {
    getAllPostsServices()
      .then(({ data } = data) => {
        setAllData(data?.data?.posts?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  const handleOpenView = (item, check) => {
    setShow(true);
    if (check == "Art") {
      setSelectedArt(item?.art);
    } else if (check == "Story") {
      // delete item.art
      setSelectedStory(item);
    }
    setCheck(check);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const handleConfirmOpen = (item, check) => {
    setOpen(true);
    setConfirmCheck(check);
    // delete item.art
    setSelectedStory(item);
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  const getPosts = () => {
    if (active == 1) {
      setLoader(true);
    }
    getPostsServices(active, searchText)
      .then(({ data } = data) => {
        setData(data?.data?.posts?.data);
        setLastPage(data?.data?.posts?.last_page);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err", err);
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   getPosts()
  // }, [active, searchText])

  let debounceTimeout;

  useEffect(() => {
    // Clear the previous timeout if it exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to call getArt after a delay
    debounceTimeout = setTimeout(() => {
      getPosts();
    }, 300); // Adjust the delay as needed (in milliseconds)

    // Clean up the timeout on unmount
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [active, searchText]);

  const handleConfirmation = (check, selectedStory) => {
    let temp = [...data];
    let findIndex = temp.findIndex((i) => i?.id == selectedStory?.id);
    if (check == "Approve") {
      approvePostServices(selectedStory?.id)
        .then(({ data } = data) => {
          if (findIndex > -1) {
            temp[findIndex]["status"] = 1;
          }
          setData(temp);
          toastMessage(data?.message);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (check == "Delete") {
      deletePostServices(selectedStory?.id)
        .then(({ data } = data) => {
          getPosts();
          toastMessage(data?.message);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const exportToExcel = () => {
    let downloadData = [];
    allData?.map((item, inx) => {
      // console.log("item", item);
      let obj = {};
      obj["Writer"] = item?.user?.name;
      obj["Story Title"] = item?.title;
      obj["Art Title"] = item?.art?.title;
      obj["Artist"] = item?.art?.user?.name;
      obj["Art Image"] = item?.art?.full_image_path;
      obj["Story Description"] = item?.story?.replace(/<\/?[^>]+(>|$)/g, "");

      downloadData.push(obj);
    });

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "ExternalContest");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "StoryCollection.xlsx");
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: "-0.5px",
            color: "#11142D",
            marginBottom: "2%",
          }}
        >
          Story Collection
        </Typography>
        <Button
          variant="contained"
          className="btnView"
          sx={{
            background: "#101828",
            color: "white",
            boxShadow: "none",
            height: "50px",
            "&:hover": {
              background: "#101828",
            },
          }}
          onClick={exportToExcel}
        >
          Download CSV{" "}
        </Button>
      </div>
      <input
        id="outlined-basic"
        type={!searchText.length ? "password" : "text"}
        variant="outlined"
        className="inputSearch"
        placeholder="Search by story title or writer"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        style={{ marginBottom: "3%" }}
      />
      {/* <input
        id="outlined-basic"
        variant="outlined"
        className="inputSearch"
        placeholder="Search"
        // onChange={(e) => setSearchText(e.target.value)}
        // value={searchText}
        autoComplete="off"
        style={{ marginBottom: '3%' }}
      /> */}
      <br />
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : data?.length ? (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Story by</TableCell>
                  <TableCell>Story Title</TableCell>
                  {/* <TableCell>Approve</TableCell> */}
                  <TableCell>Submission Time</TableCell>
                  <TableCell>Story Detail</TableCell>
                  <TableCell>Art Detail</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, inx) => (
                  <TableRow
                    key={item?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="item">
                      {inx == 9 && active != 1
                        ? active.toString() + 0
                        : active != 1
                        ? (active - 1).toString() + (inx + 1)
                        : inx + 1}
                    </TableCell>
                    <TableCell sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            item?.user?.profile_pic
                              ? item?.user?.full_profile_path
                              : PlaceholderImage
                          }
                          height="40"
                          width="40"
                          style={{ borderRadius: "50%", marginRight: "10px" }}
                        />
                        {item?.user?.name}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="tableBox">
                        {item?.title ? item?.title : "Not Provided"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="tableBox">
                        {moment(item?.created_at).format("MMM D YY, h:mm:ss a")}
                      </Box>
                    </TableCell>
                    {/* <TableCell>
                      {item.status == 'Pending' ? (
                        <Button
                          variant="contained"
                          className="btnView"
                          sx={{
                            background: ' rgba(207 , 255, 204, 0.9);',
                            marginLeft: '5%',
                            boxShadow: 'none',
                            '&:hover': {
                              background: 'rgba(207 , 255, 204, 0.9)',
                            },
                          }}
                          onClick={() => handleConfirmOpen(item, 'Approve')}
                        >
                          <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        </Button>
                      ) : (
                        <span className="approveStory">Approved</span>
                      )}
                    </TableCell> */}
                    <TableCell>
                      {" "}
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: "#101828",
                          color: "white",
                          boxShadow: "none",
                          "&:hover": {
                            background: "#101828",
                          },
                        }}
                        onClick={() => handleOpenView(item, "Story")}
                      >
                        <VisibilityIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      {item?.art ? (
                        <Button
                          variant="contained"
                          className="btnView"
                          sx={{
                            background: "#101828",
                            color: "white",
                            boxShadow: "none",

                            "&:hover": {
                              background: "#101828",
                            },
                          }}
                          onClick={() => handleOpenView(item, "Art")}
                        >
                          <PaletteIcon />
                        </Button>
                      ) : (
                        <span className="proviteStory">Private Story</span>
                      )}
                    </TableCell>

                    <TableCell>
                      <Button
                        sx={{
                          background: " rgba(255, 183, 190, 0.9);",
                          marginLeft: "5%",
                        }}
                        onClick={(e) => handleConfirmOpen(item, "Delete")}
                      >
                        <DeleteIcon sx={{ color: "red" }} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {lastPage > 1 ? (
              <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
        <ViewDetailArt
          open={show}
          handleClickOpen={handleOpenView}
          handleClose={handleCloseView}
          setSelectedArt={setSelectedArt}
          selectedArt={selectedArt}
          selectedStory={selectedStory}
          setSelectedStory={setSelectedStory}
          check={check}
          setCheck={setCheck}
        />
        <Confirmation
          open={open}
          handleClickOpen={handleConfirmOpen}
          handleClose={handleConfirmClose}
          check={confirmCheck}
          handleConfirmation={handleConfirmation}
          selectedStory={selectedStory}
          setSelectedStory={setSelectedStory}
          loader={loader}
        />
      </TableContainer>
    </Box>
  );
}
