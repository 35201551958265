import React, { useState } from "react";
import Editor from "../../shared/components/Editor";
import {
  getHomeGuide,
  updateHomeGuide,
} from "./../../shared/services/TermsPrivacyService";

function HomeGuide() {
  const [text, setText] = useState("");

  return (
    <>
      <Editor
        text={text}
        setText={setText}
        getAPI={getHomeGuide}
        updateAPI={updateHomeGuide}
        type="home"
      />
    </>
  );
}

export default HomeGuide;
