import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import Loader from "../../shared/components/Loader";
import { NoData } from "../../assets";
import { getNewsLetter } from "../../shared/services/userService";

let arr = [
  {
    email: "admin@yopmail.com",
  },
];

export default function ContactUs() {
  const [loader, setLoader] = useState(false);
  const [allNewsLetters, setAllNewsLetter] = useState([]);

  const newsLetters = () => {
    setLoader(true);
    getNewsLetter()
      .then(({ data: { data } }) => {
        setAllNewsLetter(data?.list);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    newsLetters();
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        News Letter
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box className="tableLoader">
            <Loader />
          </Box>
        ) : allNewsLetters.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Subscribed emails</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allNewsLetters?.map((item) => (
                <TableRow key={item.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{item.name?.length ? item?.name : "Not Provided"}</TableCell>
                  <TableCell>{item.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
    </Box>
  );
}
