import React, { useState } from 'react'
import Editor from '../../shared/components/Editor'
import { getTermsService, updateTermsService } from './../../shared/services/TermsPrivacyService'

function Terms() {
  const [text, setText] = useState('')

  return (
    <>
      <Editor
        text={text}
        setText={setText}
        getAPI={getTermsService}
        updateAPI={updateTermsService}
        type="terms"
      />
    </>
  )
}

export default Terms
