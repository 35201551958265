import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { NoData } from "../../assets";
import Loader from "../../shared/components/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getAllGuestStories,
  getAllExternalStories,
  deleteGuestStories,
} from "../../shared/services/postServices";
import Description from "./../../shared/components/Modals/Description";
import * as XLSX from "xlsx";
import Pagination from "../../shared/components/Pagination";
import BasicPopover from "../../shared/components/BasicPopOver";
import Confirmation from "../../shared/components/Modals/Confirmation";
import { toastMessage } from "../../shared/components/Toast";
import moment from "moment";

export default function ExternalContest() {
  const [loader, setLoader] = useState(false);
  const [allGuestStories, setAllGuestStories] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [downlaodLoader, setDownlaodLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState("");
  const [selectedStory, setSelectedStory] = useState({});

  const handleOpenView = (item) => {
    setShow(true);
    setSelectedDescription(item?.description);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const getAllExternalContests = () => {
    setLoader(true);
    getAllGuestStories(active)
      .then(({ data: { data } }) => {
        setLastPage(Math.ceil(data?.total / 10));
        setAllGuestStories(data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const exportToExcel = (stories) => {
    let data = [];
    stories?.map((item) => {
      let obj = {
        Name: item?.guest_user?.name,
        Email: item?.guest_user?.email,
        Title: item?.title,
        Description: item?.description?.replace(/<\/?[^>]+(>|$)/g, ""),
      };
      data.push(obj);
    });

    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "ExternalContest");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "ExternalContest.xlsx");
  };

  const getExternalStoriesForDownload = () => {
    setDownlaodLoader(true);
    getAllExternalStories()
      .then(({ data }) => {
        exportToExcel(data?.data);
        setDownlaodLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setDownlaodLoader(false);
      });
  };

  useEffect(() => {
    getAllExternalContests();
  }, [active]);

  const handleConfirmOpen = (item, check) => {
    setOpen(true);
    setConfirmCheck(check);
    // delete item.art
    setSelectedStory(item);
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  const handleConfirmation = (check, selectedStory) => {
    deleteGuestStories(selectedStory?.id)
      .then(({ data }) => {
        toastMessage(data?.message);
        getAllExternalContests();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          // marginBottom: "2%",
        }}
      >
        External Contest
      </Typography>
      <div
        style={{ display: "flex", justifyContent: "end", marginBottom: "20px" }}
      >
        <Button
          variant="contained"
          className="btnView"
          sx={{
            background: "#101828",
            color: "white",
            boxShadow: "none",
            "&:hover": {
              background: "#101828",
            },
            width: "170px",
            height: "40px",
          }}
          onClick={() => getExternalStoriesForDownload()}
        >
          {downlaodLoader ? <Loader /> : "Download CSV"}
        </Button>
      </div>

      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box className="tableLoader">
            <Loader />
          </Box>
        ) : allGuestStories?.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Story Title</TableCell>
                <TableCell>Submission Time</TableCell>
                <TableCell>Story Description</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allGuestStories?.map((item) => (
                <TableRow
                  key={item.name}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <BasicPopover
                      reason={
                        item.guest_user?.name?.length
                          ? item.guest_user?.name
                          : "Not Provided"
                      }
                    />
                  </TableCell>
                  <TableCell>{item?.guest_user?.email}</TableCell>
                  <TableCell>
                    {item?.title ? item?.title : "Not Provided"}
                  </TableCell>
                  <TableCell>
                    <Box className="tableBox">
                      {moment(item?.created_at).format("MMM D YY, h:mm:ss a")}
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        boxShadow: "none",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleOpenView(item)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        background: " rgba(255, 183, 190, 0.9);",
                        marginLeft: "5%",
                      }}
                      onClick={(e) => handleConfirmOpen(item, "Delete")}
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} alt="No Data" />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {loader ? null : lastPage > 1 ? (
        <Pagination lastPage={lastPage} active={active} setActive={setActive} />
      ) : (
        ""
      )}
      <Description
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        selectedDescription={selectedDescription}
        setSelectedDescription={setSelectedDescription}
      />
      <Confirmation
        open={open}
        handleClickOpen={handleConfirmOpen}
        handleClose={handleConfirmClose}
        check={confirmCheck}
        handleConfirmation={handleConfirmation}
        selectedStory={selectedStory}
        setSelectedStory={setSelectedStory}
        loader={loader}
      />
    </Box>
  );
}
