import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createGernreService, getGenresService, DeleteGenreService } from "../../shared/services/genreServices";
import { toastMessage } from "../../shared/components/Toast";
import CloseIcon from '@mui/icons-material/Close';
import { NoData } from '../../assets'


function AddGenre() {
  const [genre, setGenres] = useState([]);
  const [input, setInput] = useState("");
  const [loader,setLoader] = useState(false);

  const handleAddGenre = (e) => {
    setInput(e.target.value);
  };

  const getGenres = () => {
    setLoader(true)
    getGenresService()
      .then(({ data: { data } }) => {
        setGenres(data.genres?.map((genre) => genre));
        setLoader(false)
      })
      .catch((err) => {
        console.log("Err", err);
        setLoader(false)
      });
  };

  useEffect(() => {
    getGenres();
  }, []);


  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      if (e.target.value.replace(/[0-9]/g, "").length > 1) {
        let str = (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)).replace(/[0-9]/g, "");
        let filtered = genre.filter((i) => i.title.toLowerCase() == str.toLowerCase());
        if (filtered.length == 0) {
          // let temp = [...genre];
          // temp.push(str);
          // setGenres(temp);
          createGenre(str)
        } else {
          toastMessage("Already added.");
        }
        setInput("");
      }
      e.target.blur();
    }
  };

  const createGenre = (str) => {
    let obj = {
      title: str
    }
    createGernreService(obj)
    .then(({data:{data, message}})=>{
      toastMessage(message)
      setGenres([...genre,data?.genre])
    })
    .catch((err)=>{
      console.log("err",err)
    })
  }

  const handleDelete = (item, index) => {
    DeleteGenreService(item?.id)
      .then(({ data } = data) => {
        let temp = [...genre]
        temp.splice(index, 1)
        setGenres(temp)
        toastMessage(data?.message)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return (
    <div>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Genres
      </Typography>
      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Genres" variant="outlined" onChange={(e) => handleAddGenre(e)} value={input} onKeyDown={handleKeyDown} />
      {/* <div style={{marginTop:"5%", display:"flex"}}>
        {genre?.map((genre) => (
          <div key={genre} className="tagsGenre"><span style={{marginRight:"8%"}}>{genre}</span> <CloseIcon style={{fontSize:"17px", cursor:"pointer"}}/></div>
        ))}
      </div> */}
      <div style={{ marginTop: '4%', display: 'flex', flexWrap: 'wrap' }}>
        {loader ? (
          <Box
            sx={{
              margin: '0 auto',
            }}
          >
            <CircularProgress
              className="spinnerLoader"
              color="inherit"
            />
          </Box>
        ) : genre.length > 0 ? (
          genre?.map((item, index) => {
            return (
              <div key={index} className="tagsGenre">
                {item.title}
                <CloseIcon
                  style={{ cursor: 'pointer', fontSize: '20px' }}
                  onClick={() => handleDelete(item, index)}
                />
              </div>
            )
          })
        ) : (
          <>
          <Box className="noDataBox">
            <img src={NoData} />
          </Box>
          <Typography className="noDataText">No results found</Typography>
        </>
        )}
      </div>
    </div>
  );
}

export default AddGenre;
