import { BASE_URL } from "../utils/constant";
import { HTTP_Request } from "../utils/interceptor";
import { Endpoints } from "./endpoint";

const contestList = (paginate) => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.contestList + `/10?page=${paginate}`
  );
};

const completedList = (paginate) => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.completedList + `/10?page=${paginate}`
  );
};

const startContest = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.contest, formBody);
};

const selectWinnerService = (id) => {
  return HTTP_Request.post(
    BASE_URL + Endpoints.selectWinner + `${id}/declare-winner`
  );
};

const deleteContestService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteContest + id);
};

const unHideService = (form) => {
  return HTTP_Request.post(BASE_URL + Endpoints.unhideContest, form);
};

const updateContest = (obj, id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.updateContest + id, obj);
};

const pendingContest = (paginate) => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.pendingContest + `/10?page=${paginate}`
  );
};

const updateContestDescription = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.updateDescription, obj);
};

const getContestDescription = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getContestDescription);
};

export {
  completedList,
  contestList,
  deleteContestService,
  getContestDescription,
  pendingContest,
  selectWinnerService,
  startContest,
  unHideService,
  updateContest,
  updateContestDescription,
};
