import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";


const getGenresService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getGenres);
}

const createGernreService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createGenres, formBody);
}

const DeleteGenreService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteGenres + id);
}




export { getGenresService, createGernreService, DeleteGenreService };
