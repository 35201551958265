import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Typography } from "@mui/material";
import { PlaceholderImage, NoData } from "./../../assets/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewDetailArt from "./../../shared/components/Modals/ViewDetailArt";
import BasicPopOver from "../../shared/components/BasicPopOver";
import { transactionListService } from "../../shared/services/Transaction";
import Loader from "../../shared/components/Loader";
import Pagination from "../../shared/components/Pagination";
import moment from "moment";

export default function Subs() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedStory, setSelectedStory] = useState({});
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const handleOpenView = (item, check) => {
    setShow(true);
    setSelectedStory(item?.story);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const getReportedArt = () => {
    if (active == 1) {
      setLoader(true);
    }

    transactionListService(active)
      .then((data) => {
        setData(data?.data?.data?.transactions?.data);
        setLastPage(data?.data?.data?.transactions?.last_page);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getReportedArt();
  }, [searchText, active]);

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Donations List
      </Typography>
      {/* <input
        id="outlined-basic"
        variant="outlined"
        className="inputSearch"
        placeholder="Search"
        // onChange={(e) => setSearchText(e.target.value)}
        // value={searchText}
        autoComplete="off"
        style={{ marginBottom: '3%' }}
      /> */}
      <br />
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : data?.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>From user</TableCell>
                <TableCell>To user</TableCell>
                <TableCell>Amount ($USD)</TableCell>
                <TableCell>Donation made at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, inx) => {
                return (
                  <>
                    <TableRow key={inx}>
                      <TableCell>
                        {inx == 9 && active != 1
                          ? active.toString() + 0
                          : active != 1
                          ? (active - 1).toString() + (inx + 1)
                          : inx + 1}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={
                              item?.from_user?.profile_pic
                                ? item?.from_user?.full_profile_path
                                : PlaceholderImage
                            }
                            height="40"
                            width="40"
                            style={{ borderRadius: "50%", marginRight: "10px" }}
                          />

                          <span>{item?.from_user?.name}</span>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="tableBox">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={
                                item?.to_user?.profile_pic
                                  ? item?.to_user?.full_profile_path
                                  : PlaceholderImage
                              }
                              height="40"
                              width="40"
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            />

                            <span>{item?.to_user?.name}</span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{item?.amount + " $"}</TableCell>
                      <TableCell>
                        {item?.created_at
                          ? moment(item?.created_at).format(
                              "h:mm A -  D/MM/YYYY"
                            )
                          : "Not Provided"}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {!loader &&
        (lastPage > 1 ? (
          <Pagination
            lastPage={lastPage}
            active={active}
            setActive={setActive}
          />
        ) : (
          ""
        ))}
      <ViewDetailArt
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        setSelectedStory={setSelectedStory}
        selectedStory={selectedStory}
        check={"Story"}
      />
    </Box>
  );
}
