import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Container, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClickOpen, handleClose, setSelectedArt, selectedArt, selectedStory, setSelectedStory, check, setCheck, selectedItem, setSelectedItem }) {
  const onClose = () => {
    handleClose();
    if (setCheck) {
      setCheck("");
    }
    if (setSelectedStory) {
      setSelectedStory({});
    }
    if (setSelectedArt) {
      setSelectedArt({});
    }
  };


  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" className="dialogContainer">
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            {" "}
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              {check} detail !
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {check == "Art" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img className="ArtImageDetail" src={selectedArt?.full_cover_image_path} />
                  </Box>

                  <DetailSection heading={selectedArt?.title} detail={selectedArt?.description} />
                  <DetailSection heading="Created by" detail={selectedArt?.user?.name} />
                  {selectedItem && selectedItem?.instructions ?
                    <DetailSection heading="instructions" detail={selectedItem?.instructions} /> : null}

                  {selectedArt?.challenge ?
                    <DetailSection heading="Challenge" detail={selectedArt?.challenge} /> : null}

                  <DetailSection heading="Created on" detail={moment(selectedArt?.created_at).format("DD MMM, YYYY")} />
                  <DetailSection heading={"Posts Count"} detail={selectedArt?.posts_count} />

                  {/* <DetailSectionTags heading="Genres" arr={selectedArt?.genres} /> */}
                  <DetailSectionTags heading="Main tags" arr={selectedArt?.tags} />
                </>
              ) : check == "Story" ? (
                <>
                  <DetailSection heading={selectedStory?.title} detail={selectedStory?.story} tags={true} />
                  <DetailSection heading="Story by" detail={selectedStory?.user?.name} />



                  <DetailSection heading="Created on" detail={moment(selectedStory?.created_at).format("DD MMM, YYYY")} />
                  <DetailSection heading="Total Likes" detail={selectedStory?.likes_count} />
                  <DetailSection heading="Total Views" detail={selectedStory?.views_count} />
                  <DetailSection heading="Total comments" detail={selectedStory?.comments_count} />
                  <DetailSection heading="Shares Count" detail={selectedStory?.shares_count} />

                  {/* <DetailSectionTags heading="Genres" arr={selectedStory?.genres} /> */}
                  <DetailSectionTags heading="Tags" arr={selectedStory?.tags} />
                </>
              ) : (
                ""
              )}
            </DialogContentText>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}

const DetailSection = ({ heading, detail, tags }) => {
  return (
    <div className="detailContainer">
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "19px",
          marginBottom: "2%",
        }}
      >
        {heading}
      </Typography>
      {!tags ? (
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "left",
            color: "#808191",
          }}
        >
          {detail}
        </Typography>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: detail }} className="dangerousHTML"></p>
      )}
    </div>
  );
};

const DetailSectionTags = ({ heading, arr }) => {
  return (
    <div className="detailContainer">
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "19px",
          marginBottom: "2%",
        }}
      >
        {heading}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr?.length > 0
          ? arr?.map((item) => {
            return (
              <div className="menuTags">
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#808191",
                  }}
                >
                  {item.title}
                </Typography>
              </div>
            );
          })
          : "Not added"}
      </Box>
    </div>
  );
};
