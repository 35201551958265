import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Placeholder,
  Placeholder2,
  PlaceholderImage,
  NoData,
} from "../../assets";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewDetailArt from "./../../shared/components/Modals/ViewDetailArt";
import Confirmation from "./../../shared/components/Modals/Confirmation";
import AddArtPiece from "./../../shared/components/Modals/AddArtPiece";
import {
  getArtServies,
  deleteArtServices,
  approveArtServices,
} from "./../../shared/services/ArtServices";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AddContest from "../../shared/components/Modals/AddContest";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "./../../shared/components/Loader";
import { toastMessage } from "../../shared/components/Toast";
import Pagination from "../../shared/components/Pagination";
import {
  makeArtFeature,
  removeArtFeature,
} from "./../../shared/services/ArtServices";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import { initSocket, socket } from "../../shared/services/socketService";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MakeFeatureArt from "../../shared/components/Modals/MakeFeatureArt";
import BasicPopover from "../../shared/components/BasicPopOver";

function AddArt() {
  const [selectedArt, setSelectedArt] = useState({});
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = useState("");
  const [artData, setArtData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [filter, setFilter] = useState("");
  const [editArt, setEditArt] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    initSocket();
  }, []);

  const handleConfirmation = (check, item) => {
    let temp = [...artData];
    let index = temp.findIndex((i) => i?.id == item?.id);
    if (check == "Approve") {
      approveArtServices(item?.id)
        .then(({ data } = data) => {
          if (index > -1) {
            temp[index]["is_approved"] = 1;
          }
          setArtData(temp);
          toastMessage(data?.message);
          const room = item?.user?.id;
          const sendData = {
            room: room,
            status: true,
          };

          socket.emit("notification", sendData);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (check == "Delete") {
      deleteArtServices(item?.id)
        .then(({ data } = data) => {
          if (index > -1) {
            temp.splice(index, 1);
          }
          setArtData(temp);
          toastMessage(data?.message);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (
      check == "Make feature art" ||
      check == "Remove publish artwork"
    ) {
      if (selectedArt?.is_approved == 0) {
        toastMessage("Art is not approved", "error");
      } else {
        if (selectedArt?.is_featured == 0) {
          makeArtFeature({ art_id: selectedArt?.id })
            .then(({ data: { data, message } }) => {
              getArt();
              toastMessage(message);
              let temp = [...artData];
              let index = temp.findIndex((i) => i.id == selectedArt?.id);
              if (index > -1) {
                temp[index]["is_featured"] = 1;
              }
              setArtData(temp);
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else if (selectedArt?.is_featured == 1) {
          removeArtFeature({ art_id: selectedArt?.id })
            .then(({ data: { data, message } }) => {
              toastMessage(message);
              let temp = [...artData];
              let index = temp.findIndex((i) => i.id == selectedArt?.id);
              if (index > -1) {
                temp[index]["is_featured"] = 0;
              }
              setArtData(temp);
              getArt();
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      }
    }
  };

  const handleClickOpen = (item) => {
    setOpen(true);
    setSelectedArt(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenView = (item) => {
    setShow(true);
    setSelectedArt(item);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const handleConfirmOpen = (check, item) => {
    setShowConfirm(true);
    setSelectedArt(item);
    setCheck(check);
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  const handleOpenAdd = () => {
    setShowAdd(true);
  };

  const handleCloseAdd = () => {
    setShowAdd(false);
  };

  const getArt = () => {
    if (active == 1) {
      setLoader(true);
    }

    getArtServies(active, filter, searchText)
      .then(({ data } = data) => {
        setLastPage(data?.data?.arts?.last_page);
        setArtData(data?.data?.arts?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   getArt();
  // }, [active, filter, searchText]);

  let tablularHead = [
    "#",
    "Art Piece",
    "Art by",
    // "Approve",
    "Challenge",
    "Details",
    "Publish Artwork",
    "Contest",
    // 'Update',
    "Delete",
    "Edit",
  ];

  const handleArt = (item) => {
    setArtData([item, ...artData]);
  };

  const handleEdit = (item) => {
    setSelectedArt(item);
    handleOpenAdd();
    setEditArt(true);
  };

  const setEdit = (item) => {
    let temp = [...artData];
    let index = temp.findIndex((i) => i?.id == item?.id);
    if (index > -1) {
      let user = temp.user;
      temp[index] = item;
      temp["user"] = user;
    }
    setArtData(temp);
  };

  let debounceTimeout;

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      getArt();
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [active, filter, searchText]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: "-0.5px",
            color: "#11142D",
            marginBottom: "2%",
          }}
        >
          Art collection
        </Typography>
        <Button
          sx={{
            height: "30px",
            textTransform: "none",
            background: "#101828",
            "&:hover": {
              background: "#101828",
            },
          }}
          variant="contained"
          onClick={() => handleOpenAdd()}
        >
          Post Art !
        </Button>
      </Box>
      {/* <input
        id="outlined-basic"
        variant="outlined"
        className="inputSearch"
        placeholder="Search"
        // onChange={(e) => setSearchText(e.target.value)}
        // value={searchText}
        autoComplete="off"
        style={{ marginBottom: '3%' }}
      /> */}
      <br />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Filter filter={filter} setFilter={setFilter} />
      </div>
      <input
        id="outlined-basic"
        type={!searchText.length ? "password" : "text"}
        variant="outlined"
        className="inputSearch"
        placeholder="Search by art title or artist"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="new-password"
        style={{ marginBottom: "3%" }}
      />
      <ArtTable
        tablularHead={tablularHead}
        tabularArr={artData}
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        openConfirm={showConfirm}
        handleConfirmOpen={handleConfirmOpen}
        handleConfirmClose={handleConfirmClose}
        openContest={open}
        clickOpenContest={handleClickOpen}
        clickCloseContest={handleClose}
        loader={loader}
        selectedArt={selectedArt}
        lastPage={lastPage}
        active={active}
        setActive={setActive}
        handleEdit={handleEdit}
        getArtServies={getArt}
      />
      <ViewDetailArt
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        check={"Art"}
      />
      <Confirmation
        open={showConfirm}
        handleClickOpen={handleConfirmOpen}
        handleClose={handleConfirmClose}
        check={check}
        setCheck={setCheck}
        handleConfirmation={handleConfirmation}
        selectedArt={selectedArt}
      />
      <AddArtPiece
        open={showAdd}
        handleClickOpen={handleOpenAdd}
        handleClose={handleCloseAdd}
        handleArt={handleArt}
        selectedArt={selectedArt}
        editArt={editArt}
        setSelectedArt={setSelectedArt}
        setEditArt={setEditArt}
        setEdit={setEdit}
        getArt={getArt}
      />
    </>
  );
}

export default AddArt;

function ArtTable({
  tablularHead,
  tabularArr,
  open,
  handleClickOpen,
  handleClose,
  openConfirm,
  handleConfirmOpen,
  handleConfirmClose,
  openContest,
  clickOpenContest,
  clickCloseContest,
  loader,
  selectedArt,
  lastPage,
  active,
  setActive,
  handleEdit,
  getArtServies,
}) {
  const [openFeature, setOpenFeature] = useState(false);
  const [featureArt, setFeatureArt] = useState(null);

  const handleOpenFeature = (item) => {
    setOpenFeature(true);
    setFeatureArt(item);
  };

  const handleCloseFeature = () => {
    setOpenFeature(false);
  };
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      {loader ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
        >
          <Loader />
        </Box>
      ) : tabularArr?.length ? (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tablularHead?.map((list, inx) => {
                  return <TableCell key={inx}>{list}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tabularArr?.map((item, inx) => (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {inx == 9 && active != 1
                      ? active.toString() + 0
                      : active != 1
                      ? (active - 1).toString() + (inx + 1)
                      : inx + 1}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      className="tableBox"
                    >
                      <img
                        src={
                          item?.full_cover_image_path
                            ? item?.full_cover_image_path
                            : PlaceholderImage
                        }
                        height="40"
                        width="40"
                        style={{ borderRadius: "50%", marginRight: "10px" }}
                      />
                      {item?.title ? item?.title : "Not Provided"}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={
                          item?.user?.profile_pic
                            ? item?.user?.full_profile_path
                            : PlaceholderImage
                        }
                        height="40"
                        width="40"
                        style={{ borderRadius: "50%", marginRight: "10px" }}
                      />
                      {item?.user?.name}
                    </Box>
                  </TableCell>
                  {/* <TableCell>
                    {item?.is_approved == 0 ? (
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: " rgba(207 , 255, 204, 0.9);",
                          marginLeft: "5%",
                          boxShadow: "none",
                          "&:hover": {
                            background: "rgba(207 , 255, 204, 0.9)",
                          },
                        }}
                        onClick={() => handleConfirmOpen("Approve", item)}
                      >
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      </Button>
                    ) : (
                      <span className="approveStory">Approved</span>
                    )}
                  </TableCell> */}
                  <TableCell>
                    <BasicPopover
                      reason={
                        item?.challenge ? item?.challenge : "Not Provided"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleClickOpen(item)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>

                  <TableCell>
                    {item?.is_featured == 0 ? (
                      <Button
                        sx={{
                          background: "#B3B3B3",
                          marginLeft: "5%",
                          "&:hover": {
                            background: "#dcdbdb",
                          },
                        }}
                        onClick={(e) => handleOpenFeature(item)}
                      >
                        <PermMediaIcon sx={{ color: "white" }} />
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          background: "rgba(255, 183, 190, 0.9)",
                          marginLeft: "5%",
                          "&:hover": {
                            background: "#dcdbdb",
                          },
                        }}
                        onClick={(e) =>
                          handleConfirmOpen("Remove publish artwork", item)
                        }
                      >
                        <FolderDeleteIcon sx={{ color: "red" }} />
                      </Button>
                    )}
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#50934B",
                        color: "white",
                        "&:hover": {
                          background: "#50934B",
                        },
                      }}
                      onClick={() => clickOpenContest(item)}
                    >
                      <EmojiEventsIcon />
                    </Button>
                  </TableCell>

                  <TableCell>
                    <Button
                      sx={{
                        background: " rgba(255, 183, 190, 0.9);",
                        marginLeft: "5%",
                      }}
                      onClick={(e) => handleConfirmOpen("Delete", item)}
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </TableCell>

                  <TableCell>
                    <Button
                      sx={{
                        background: "#acc8cc",
                        marginLeft: "5%",
                      }}
                      // onClick={(e) => handleConfirmOpen("Delete", item)}
                      onClick={() => handleEdit(item)}
                    >
                      <ModeEditIcon sx={{ color: "white" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {lastPage > 1 ? (
            <Pagination
              lastPage={lastPage}
              active={active}
              setActive={setActive}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <Box className="noDataBox">
            <img src={NoData} />
          </Box>
          <Typography className="noDataText">No results found</Typography>
        </>
      )}
      <AddContest
        open={openContest}
        handleClickOpen={clickOpenContest}
        handleClose={clickCloseContest}
        selectedArt={selectedArt}
      />
      <MakeFeatureArt
        open={openFeature}
        handleClose={handleCloseFeature}
        featureArt={featureArt}
        setFeatureArt={setFeatureArt}
        getArtServies={getArtServies}
      />
    </TableContainer>
  );
}

function Filter({ filter, setFilter }) {
  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <FormControl sx={{ minWidth: 230, marginBottom: "20px" }}>
        <Select
          value={filter}
          onChange={handleChange}
          displayEmpty
          IconComponent={FilterListIcon}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={""}>All</MenuItem>
          <MenuItem value={"posts_count"}>Posts Count</MenuItem>
          <MenuItem value={"views_count"}>Views Count</MenuItem>
          <MenuItem value={"comments_count"}>Comment Count</MenuItem>
          <MenuItem value={"post_likes_count"}>Post Likes Count</MenuItem>
          <MenuItem value={"post_views_count"}>Post Views Count</MenuItem>
          <MenuItem value={"post_shares_count"}>Post Shares Count</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
