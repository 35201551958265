import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Container } from "@mui/material";
import Loader from "../Loader";
import Autocomplete from "./../AutoComplete";
import { userService } from "../../services/userService";
import { toastMessage } from "../../components/Toast";
import { createTestimonialService, updateTestimonialService } from "./../../services/TestimonialService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClickOpen, handleClose, testimonial, setTestimonial, update, allTestimonial, setAllTestimonials, setUpdate, getTestimonial }) {
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (testimonial?.review) {
      setContent(testimonial.review);
    }

    if (testimonial?.user) {
      setSelectedUser(testimonial?.user);
    }
  }, [testimonial]);

  const getAllUsers = () => {
    let obj = {
      page: 1,
      pagination: 20,
      search_text: searchText,
      filter: "all",
    };

    userService(obj)
      .then(
        ({
          data: {
            data: { userList },
          },
        }) => {
          setUserList(userList?.data);
        },
      )
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [searchText]);

  const onClose = () => {
    setSelectedUser(null);
    setSearchText("");
    handleClose();
    setTestimonial(null);
    setContent("");
    setUpdate(false);
  };

  const handleChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 255) {
      setContent(inputText);
    }
  };

  const handleCreate = () => {
    if (!selectedUser || !content.length) {
      toastMessage(`Please ${!selectedUser ? "select a user" : "add testimonial"} `, "error");
    } else {
      setLoader(true);
      let obj = {
        user_id: selectedUser?.id,
        review: content,
      };

      if (!update) {
        createTestimonialService(obj)
          .then(({ data: { data, message } }) => {
            setLoader(false);
            toastMessage(message);
            setAllTestimonials([...allTestimonial, data?.testimonial]);
            onClose();
          })
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
          });
      } else {
        updateTestimonialService(obj, testimonial?.id)
          .then(({ data: { data, message } }) => {
            setLoader(false);
            toastMessage(message);
            let temp = [...allTestimonial];
            let index = allTestimonial.findIndex((i) => i.id == testimonial?.id);
            if (index > -1) {
              temp[index].review = content;
              temp[index].user = selectedUser;
            }
            setAllTestimonials(temp);
            onClose();
          })
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
          });
      }
    }
  };

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" className="dialogContainer">
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
            }}
            color="text.secondary"
          >
            Add Testimonial
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Autocomplete userList={userList} setSearchText={setSearchText} searchText={searchText} setUser={setSelectedUser} user={selectedUser} testimonial={testimonial} />
              <br />
              <div>
                Content:
                <textarea style={{ resize: "none" }} className="inputFAQ textAreaFAQ" value={content} onChange={handleChange}></textarea>
                <p style={{ margin: "0px" }}>{255 - content.length} words left</p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button onClick={onClose} variant="contained" className="customButton2">
              Close
            </Button>
            <Button variant="contained" className="customButton" onClick={() => handleCreate()}>
              {loader ? <Loader /> : update ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
