import Dashboard from "../pages/home/Dashboard";
import Login from "./../pages/auth/Login";
import Users from "./../pages/home/Users";
import AddArt from "../pages/home/AddArt";
import ReportedArt from "../pages/home/ReportedArt";
import ReportedStory from "../pages/home/ReportedStory";
import ApproveStory from "../pages/home/ApproveStory";
import ReportedComment from "../pages/home/ReportedComment";
import FAQ from "../pages/home/FAQ";
import Privacy from "../pages/home/Privacy";
import WritingTips from "../pages/home/WritingTips";
import Terms from "../pages/home/Terms";
import OngoingContest from "../pages/home/OngoingContest";
import CompletedContest from "../pages/home/CompletedContest";
import Subscribed from "../pages/home/Subscribed";
import BlockedUsers from "../pages/home/BlockedUsers";
import AddGenre from "../pages/home/AddGenre";
import Testimonial from "../pages/home/Testimonial";
import ContactUs from "../pages/home/ContactUs";
import Newsletter from "../pages/home/Newsletter";
import Announcements from "../pages/home/Announcements";
import PaidWork from "../pages/home/PaidWork";
import FeatureArtists from "../pages/home/FeatureArtists";
import ReportedUsers from "../pages/home/ReportedUsers";
import Writers from "../pages/home/Writers";
import Artists from "../pages/home/Artists";
import FreatureWriters from "../pages/home/FreatureWriters";
import PendingContest from "../pages/home/PendingCompetition";
import FeatureArt from "../pages/home/FeatureArt";
import ExternalContest from "../pages/home/externalContest";
import ContestDeadline from "../pages/home/contestDealine";
import HomeGuide from "../pages/home/homeGuide";

const privateRoutes = [
  {
    component: Dashboard,
    path: "/",
    title: "Dashboard",
  },
  {
    component: Users,
    path: "/users",
    title: "Users",
  },
  {
    component: ApproveStory,
    path: "/approve",
    title: "Approve Story",
  },
  {
    component: ReportedArt,
    path: "/reportedArt",
    title: "Reported Art",
  },
  {
    component: ReportedStory,
    path: "/reportedStory",
    title: "Reported Story",
  },
  {
    component: ReportedComment,
    path: "/reportedComment",
    title: "Reported Comment",
  },
  {
    component: AddArt,
    path: "/art",
    title: "Art Collection",
  },
  {
    component: OngoingContest,
    path: "/ongoingContest",
    title: "Ongoing Contest",
  },
  {
    component: PendingContest,
    path: "/pendingWinner",
    title: "Pending Winner",
  },
  {
    component: CompletedContest,
    path: "/completedContest",
    title: "Completed Contest",
  },
  {
    component: Subscribed,
    path: "/buy-me-coffee",
    title: "FAQs",
  },
  {
    component: BlockedUsers,
    path: "/blocked-users",
    title: "Blocked Users",
  },
  {
    component: FAQ,
    path: "/faq",
    title: "FAQs",
  },
  {
    component: Terms,
    path: "/terms",
    title: "Terms and Condition",
  },
  {
    component: Privacy,
    path: "/privacy",
    title: "Privacy Policy",
  },
  {
    component: WritingTips,
    path: "/writingTips",
    title: "Writing Tips",
  },
  {
    component: AddGenre,
    path: "/addGenre",
    title: "Genres",
  },
  {
    component: Testimonial,
    path: "/testimonial",
    title: "Testimonial",
  },
  {
    component: ContactUs,
    path: "/contactus",
    title: "Contact Us",
  },
  {
    component: Newsletter,
    path: "/newsLetter",
    title: "News Letter",
  },
  {
    component: Announcements,
    path: "/announcements",
    title: "Announcement",
  },
  {
    component: PaidWork,
    path: "/store",
    title: "Store",
  },
  {
    component: FeatureArtists,
    path: "/featureArtists",
    title: "Feature Artists",
  },
  {
    component: ReportedUsers,
    path: "/reportedUsers",
    title: "Reported Users",
  },
  {
    component: Writers,
    path: "/writers",
    title: "Writers",
  },
  {
    component: Artists,
    path: "/artists",
    title: "Artists",
  },
  {
    component: FreatureWriters,
    path: "/featureWriter",
    title: "Artists",
  },
  {
    component: FeatureArt,
    path: "/feature-art",
    title: "Featured Art",
  },
  {
    component: ExternalContest,
    path: "/external-contest",
    title: "External-contest",
  },
  {
    component: ContestDeadline,
    path: "/contestDeadline",
    title: "Contest Deadline",
  },
  { component: HomeGuide, path: "/home-guide", title: "Home Guide" },
];

const publicRoutes = [
  {
    component: Login,
    path: "/",
    title: "Login",
  },
];

export { publicRoutes, privateRoutes };
