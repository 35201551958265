import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const getFAQServices = () => {

  return HTTP_Request.get(BASE_URL + Endpoints.getFAQ);
}

const createFAQServices = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createFAQ, formBody);
}

const updateFAQServices = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateFAQ, formBody);
}

const updateOrderService = (obj) => {
  // let formBody = new FormData();
  // Object.keys(obj).forEach((key) => {
  //   formBody.append(key, obj[key]);
  // });
  return HTTP_Request.post(BASE_URL + Endpoints.updateFAQOrder, obj);
}

const deleteFAQService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteFAQ + id);
}

const faqCategoryService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.faqCategory);

}

const getWritingServices = () => {

  return HTTP_Request.get(BASE_URL + Endpoints.getWritingTips);
}

const createWritingServices = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createWritingTips, formBody);
}

const updateWritingServices = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateWritingTips, formBody);
}

const updateWritingServiceOrder = (obj) => {
  // let formBody = new FormData();
  // Object.keys(obj).forEach((key) => {
  //   formBody.append(key, obj[key]);
  // });
  return HTTP_Request.post(BASE_URL + Endpoints.updateWritingTipsOrder, obj);
}

const deleteWritingService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteTips + id);
}



export {
  getFAQServices,
  createFAQServices,
  updateFAQServices,
  updateOrderService,
  deleteFAQService,
  faqCategoryService,
  getWritingServices,
  createWritingServices,
  updateWritingServices,
  updateWritingServiceOrder,
  deleteWritingService
};
