import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { htmlToText } from "../utils/helper";

function BasicPopover({ reason }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function shortenString(str) {
    if (str?.length > 20) {
      return str.substring(0, 20) + "...";
    } else {
      return str;
    }
  }

  const popoverArrowStyles = {
    position: "absolute",
    top: "-10px",
    left: "calc(50% - 10px)",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "10px 10px 0 10px",
    borderColor: `transparent transparent ${"#3f51b5"} transparent`, // Replace 'theme.palette.primary.main' with a color value
  };

  return (
    <div>
      <div
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        {shortenString(htmlToText(reason))}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: "280px",
            bgcolor: "#1E1E1E",
            color: "primary.contrastText",
            borderRadius: "16px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            p: 2,
            position: "relative",
          },
        }}
      >
        <div style={popoverArrowStyles}></div>
        <Typography>{htmlToText(reason)}</Typography>
      </Popover>
    </div>
  );
}

export default BasicPopover;
