import { Box, Button, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllFeatureWriters, makeFeatureWriter, removeFeatureWriter } from "../../shared/services/postServices";
import Confirmation from "../../shared/components/Modals/Confirmation";
import ViewDetail from "../../shared/components/Modals/ViewDetail";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import EditOffIcon from "@mui/icons-material/EditOff";
import { blockService, unBlockService } from "../../shared/services/userService";
import { toastMessage } from "../../shared/components/Toast";
import Loader from "../../shared/components/Loader";
import { NoData, PlaceholderImage } from "../../assets";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { updateUserOrder } from "../../shared/services/userService";

function FeatureArtists() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [check, setCheck] = useState("");
  const [openView, setOpenView] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleOpen = (item, check) => {
    setOpen(true);
    setSelectedUser(item);
    setCheck(check);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenView = (item) => {
    setOpenView(true);
    setSelectedUser(item);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  let tablularHead = [
    "Name",
    "Email",
    "Platform",
    "Details",
    // "Delete User",
    "Status",
    "Feature Artists",
  ];

  const handleConfirmation = (resp, selectedUser) => {
    let id = parseInt(String(selectedUser?.id).match(/\d+/)) || NaN;
    let temp = [...users];
    for (let i = 0; i < temp.length; i++) {
      temp[i]["id"] = parseInt(String(temp[i]?.id).match(/\d+/)) || NaN;
    }
    setUsers(temp);
    if (resp == "Block") {
      blockService({ id: id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = true;
          }
          setUsers(temp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (resp == "Unblock") {
      unBlockService({ id: id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = false;
          }
          setUsers(temp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (resp == "Make feature artist" || resp == "Remove from feature artists") {
      if (selectedUser?.is_featured == 0) {
        makeFeatureWriter({ user_id: id })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            let temp = [...users];
            let index = temp.findIndex((i) => i.id == id);
            if (index > -1) {
              temp[index]["is_featured"] = 1;
            }
            setUsers(temp);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (selectedUser?.is_featured == 1) {
        removeFeatureWriter({ user_id: id })
          .then(({ data: { data, message } }) => {
            toastMessage(message);
            let temp = [...users];
            let index = temp.findIndex((i) => i?.id == id);
            if (index > -1) {
              temp.splice(index, 1);
            }
            setUsers(temp);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const getFeatureWriters = () => {
    setLoader(true);
    getAllFeatureWriters()
      .then(({ data: { data } }) => {
        let temp = [...data];
        for (let i = 0; i < temp.length; i++) {
          temp[i]["id"] = "item-" + temp[i]["id"];
        }
        temp.sort((a, b) => a.order - b.order);
        setLoader(false);
        setUsers(temp);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getFeatureWriters();
  }, []);

  return (
    <div>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Feature Writers
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
            <Loader />
          </Box>
        ) : users.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tablularHead?.map((item) => {
                  return (
                    <>
                      <TableCell key={item?.id}>{item}</TableCell>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            {/* <UserData users={users} handleOpen={handleOpen} handleOpenView={handleOpenView} /> */}
            <DragableItems items={users} setItems={setUsers} handleOpen={handleOpen} handleOpenView={handleOpenView} />
          </Table>
        ) : (
          <>
            <Box className="noDataBox" style={{ marginTop: "5%" }}>
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
        <Confirmation open={open} handleClickOpen={handleOpen} handleClose={handleClose} check={check} selectedUser={selectedUser} handleConfirmation={handleConfirmation} />
        <ViewDetail open={openView} handleClickOpen={handleOpenView} handleClose={handleCloseView} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
      </TableContainer>
    </div>
  );
}

export default FeatureArtists;

const DragableItems = ({ items, setItems, handleOpen, handleOpenView }) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setOrder(newItems);
    setItems(newItems);
  };

  const setOrder = (newItems) => {
    let temp = [...newItems];
    let ids = [];
    let order = [];

    temp.map((i, inx) => {
      ids.push(parseInt((i?.id).match(/\d+/)[0]));
      order.push(inx + 1);
    });

    let obj = {
      id: ids,
      order: order,
    };

    console.log("obj", obj);

    updateUserOrder(obj)
      .then(({ data: { data, message } }) => {
        toastMessage(message);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <TableRow {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="dragList">
                    <TableRowed item={item} inx={index} handleOpen={handleOpen} handleOpenView={handleOpenView} />
                    {/* <CardFAQ question={item.title} answer={item.description} open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} item={item} setSelected={setSelected} items={items} setItems={setItems} /> */}
                  </TableRow>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const TableRowed = ({ item, inx, handleOpen, handleOpenView }) => {
  return (
    <>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DragIndicatorIcon sx={{ marginRight: "5%", color: "grey" }} />
          <img src={item?.profile_pic ? item?.full_profile_path : PlaceholderImage} height="40" width="40" style={{ borderRadius: "50%", marginRight: "10px" }} />

          {item?.name}
        </Box>{" "}
      </TableCell>
      <TableCell>{item?.email}</TableCell>
      <TableCell>{item?.social_network == 1 ? "Facebook" : item?.social_network == 2 ? "Twitter" : "via email"}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          className="btnView"
          sx={{
            background: "#101828",
            color: "white",
            "&:hover": {
              background: "#101828",
            },
          }}
          onClick={() => handleOpenView(item)}
        >
          <VisibilityIcon />
        </Button>
      </TableCell>
      <TableCell>
        {!item?.is_blocked ? (
          <Button
            sx={{
              background: " rgba(255, 183, 190, 0.9);",
              marginLeft: "5%",
            }}
            onClick={() => handleOpen(item, "Block")}
          >
            <LockPersonIcon style={{ color: "red" }} />
          </Button>
        ) : (
          <Button
            sx={{
              background: " rgba(207 , 255, 204, 0.9);",
              marginLeft: "5%",
            }}
            onClick={() => handleOpen(item, "Unblock")}
          >
            <LockOpenIcon style={{ color: "green" }} />
          </Button>
        )}
      </TableCell>
      <TableCell>
        {item?.is_featured == 0 ? (
          <Button
            sx={{
              background: "rgba(207 , 255, 204, 0.9)",
              marginLeft: "5%",
            }}
            onClick={(e) => handleOpen(item, "Make feature artist")}
          >
            <HistoryEduIcon style={{ color: "green" }} />
          </Button>
        ) : (
          <Button
            sx={{
              background: "rgba(255, 183, 190, 0.9)",
              marginLeft: "5%",
            }}
            onClick={(e) => handleOpen(item, "Remove from feature artists")}
          >
            <EditOffIcon style={{ color: "red" }} />
          </Button>
        )}
      </TableCell>
    </>
  );
};

// const UserData = ({ users, handleOpen, handleOpenView }) => {
//   return (
//     <>
//       {users?.map((item, inx) => {
//         return (
//           <TableRow key={inx}>
//             <TableCell>
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 <img src={item?.profile_pic ? item?.full_profile_path : PlaceholderImage} height="40" width="40" style={{ borderRadius: "50%", marginRight: "10px" }} />

//                 {item?.name}
//               </Box>{" "}
//             </TableCell>
//             <TableCell>{item?.email}</TableCell>
//             <TableCell>{item?.social_network == 1 ? "Facebook" : item?.social_network == 2 ? "Twitter" : "via email"}</TableCell>
//             <TableCell>
//               <Button
//                 variant="contained"
//                 className="btnView"
//                 sx={{
//                   background: "#101828",
//                   color: "white",
//                   "&:hover": {
//                     background: "#101828",
//                   },
//                 }}
//                 onClick={() => handleOpenView(item)}
//               >
//                 <VisibilityIcon />
//               </Button>
//             </TableCell>
//             <TableCell>
//               {!item?.is_blocked ? (
//                 <Button
//                   sx={{
//                     background: " rgba(255, 183, 190, 0.9);",
//                     marginLeft: "5%",
//                   }}
//                   onClick={() => handleOpen(item, "Block")}
//                 >
//                   <LockPersonIcon style={{ color: "red" }} />
//                 </Button>
//               ) : (
//                 <Button
//                   sx={{
//                     background: " rgba(207 , 255, 204, 0.9);",
//                     marginLeft: "5%",
//                   }}
//                   onClick={() => handleOpen(item, "Unblock")}
//                 >
//                   <LockOpenIcon style={{ color: "green" }} />
//                 </Button>
//               )}
//             </TableCell>
//             <TableCell>
//               {item?.is_featured == 0 ? (
//                 <Button
//                   sx={{
//                     background: "rgba(207 , 255, 204, 0.9)",
//                     marginLeft: "5%",
//                   }}
//                   onClick={(e) => handleOpen(item, "Make feature artist")}
//                 >
//                   <HistoryEduIcon style={{ color: "green" }} />
//                 </Button>
//               ) : (
//                 <Button
//                   sx={{
//                     background: "rgba(255, 183, 190, 0.9)",
//                     marginLeft: "5%",
//                   }}
//                   onClick={(e) => handleOpen(item, "Remove from feature artists")}
//                 >
//                   <EditOffIcon style={{ color: "red" }} />
//                 </Button>
//               )}
//             </TableCell>
//           </TableRow>
//         );
//       })}
//     </>
//   );
// };
