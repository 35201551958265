import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Button, Typography } from '@mui/material'
import { Placeholder, PlaceholderImage, NoData } from '../../assets'
import PaletteIcon from '@mui/icons-material/Palette'
import ViewDetailArt from '../../shared/components/Modals/ViewDetailArt'
import BasicPopOver from '../../shared/components/BasicPopOver'
import { ReportServices } from '../../shared/services/ArtServices'
import Loader from '../../shared/components/Loader'
import Pagination from '../../shared/components/Pagination'

export default function ReportedArt() {
  const [data, setData] = useState([])
  const [show, setShow] = useState(false)
  const [selectedArt, setSelectedArt] = useState()
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(false)
  const [lastPage, setLastPage] = useState(0)
  const [active, setActive] = useState(1)

  const handleOpenView = (item, check) => {
    setShow(true)
    setSelectedArt(item?.reportable)
  }

  const handleCloseView = () => {
    setShow(false)
  }

  const getReportedArt = () => {
    if (active == 1) {
      setLoader(true)
    }
    ReportServices('Art', searchText, active)
      .then(({ data } = data) => {
        setLastPage(data?.data?.reports?.last_page)
        setData(data?.data?.reports?.data)
        setLoader(false)
      })
      .catch((err) => {
        console.log('err', err)
        setLoader(false)
      })
  }

  useEffect(() => {
    getReportedArt()
  }, [searchText, active])

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Typography
          sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 32,
            lineHeight: '48px',
            letterSpacing: '-0.5px',
            color: '#11142D',
            marginBottom: '2%',
          }}
        >
          Reported Art
        </Typography>
        {/* <input
          id="outlined-basic"
          variant="outlined"
          className="inputSearch"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          autoComplete="off"
          style={{ marginBottom: '3%' }}
        /> */}
        {loader ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}
          >
            <Loader />
          </Box>
        ) : data?.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Reported by</TableCell>
                <TableCell>Reported art</TableCell>
                <TableCell>Reported art detail</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, inx) => {
                return (
                  <TableRow key={inx}>
                    <TableCell>
                      {inx == 9 && active != 1
                        ? active.toString() + 0
                        : active != 1
                        ? (active - 1).toString() + (inx + 1)
                        : inx + 1}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={
                            item?.reported_by?.profile_pic
                              ? item?.reported_by?.full_profile_path
                              : PlaceholderImage
                          }
                          height="40"
                          width="40"
                          style={{ borderRadius: '50%', marginRight: '10px' }}
                        />
                        {item?.reported_by?.name}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="tableBox">
                        {item?.reportable?.title
                          ? item?.reportable?.title
                          : 'Not Provided'}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: '#101828',
                          color: 'white',
                          boxShadow: 'none',
                          '&:hover': {
                            background: '#101828',
                          },
                        }}
                        onClick={() => handleOpenView(item, 'Art')}
                      >
                        <PaletteIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <BasicPopOver reason={item?.reason} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {!loader && lastPage > 1 ? (
        <Pagination lastPage={lastPage} active={active} setActive={setActive} />
      ) : (
        ''
      )}
      <ViewDetailArt
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        check={'Art'}
      />
    </>
  )
}
