import React from 'react'
import { ResponsivePie } from '@nivo/pie'

const MyResponsivePie = ({ stats }) => {
  const data = [
    { id: 'Blocked Users', value: stats.blocked_users_count, color: '#f6c044' },
    { id: 'Total Users', value: stats.all_users_count, color: '#3b7ba9' },
    {
      id: 'Active Users',
      value: stats.unblocked_users_count,
      color: '#d04a35',
    },
  ]

  return (
    <div style={{ height: '400px' }}>
      <div
        style={{
          fontSize: '1.875rem',
          margin: '0px',
          fontWeight: 700,
          lineHeight: 1.5,
          textAlign: 'center',
          color: 'rgb(6, 27, 100)',
          marginTop: '7%',
        }}
      >
        Users data
      </div>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', '0.2']] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}

export default MyResponsivePie
