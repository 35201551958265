import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutoComplete from "./../../shared/components/AutoComplete";
import AddTestimonial from "./../../shared/components/Modals/AddTestimonial";
import { getTestimonialService, deleteTestimonialService } from "../../shared/services/TestimonialService";
import { NoData, PlaceholderImage } from "../../assets";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Confirmation from "../../shared/components/Modals/Confirmation";
import Loader from "../../shared/components/Loader";

function Testimonial() {
  const [open, setOpen] = useState(false);
  const [allTestimonial, setAllTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClickDelete = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTestimonial = () => {
    setLoader(true);
    getTestimonialService()
      .then(({ data: { data } }) => {
        setAllTestimonials(data.testimonials);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getTestimonial();
  }, []);

  const handleEdit = (testimonialId) => {
    const testimonial = allTestimonial.find((item) => item.id === testimonialId);
    setSelectedTestimonial(testimonial || {});
    setOpen(true);
    setUpdate(true);
  };

  const handleDelete = (testimonialId) => {
    deleteTestimonialService(testimonialId)
      .then(({ data: { data } }) => {
        let temp = [...allTestimonial];
        let index = temp.findIndex((i) => i.id == testimonialId);
        if (index > -1) {
          temp.splice(index, 1);
        }
        setAllTestimonials(temp);
      })
      .catch(() => {});
  };

  const handleConfirmation = (check) => {
    if (check == "Delete") {
      handleDelete(selectedTestimonial.id);
    }
  };

  return (
    <div>
      <Box
        sx={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "24px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
                marginBottom: "2%",
              }}
            >
              Testimonials
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                width: "100%",
                background: "#101828",
                border: "1px solid #101828",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                "&:hover": {
                  backgroundColor: "#000000",
                },
              }}
              variant="contained"
              onClick={() => handleClickOpen()}
            >
              Add Another
            </Button>
          </Box>
        </Box>
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
            <Loader />
          </Box>
        ) : allTestimonial?.length ? (
          <div>
            {allTestimonial?.map((item) => {
              return (
                <div className="testimonial testimonialUser" key={item.id}>
                  <div>
                    <img src={item?.user?.profile_pic ? item?.user?.full_profile_path : PlaceholderImage} height={40} width={40} style={{ borderRadius: "50%", marginRight: "10px" }} />
                  </div>
                  <div>
                    <p style={{ margin: "0", fontWeight: "600" }}>{item?.user?.name}</p>
                    <p style={{ margin: "0" }}>{item.review}</p>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <EditIcon style={{ cursor: "pointer", marginRight: "8px" }} onClick={() => handleEdit(item.id)} />
                    <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleClickDelete(item)} />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </Box>
      <AddTestimonial open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} testimonial={selectedTestimonial} setTestimonial={setSelectedTestimonial} allTestimonial={allTestimonial} setAllTestimonials={setAllTestimonials} update={update} setUpdate={setUpdate} getTestimonial={getTestimonial} />
      <Confirmation open={openDelete} handleClickOpen={handleClickDelete} handleClose={handleCloseDelete} check={"Delete"} handleConfirmation={handleConfirmation} />
    </div>
  );
}

export default Testimonial;
