let Endpoints = {
  login: "/admin-login",
  logout: "/admin-logout",
  user: "/get-users",
  blockUser: "/block-user",
  unBlockUser: "/unblock-user",
  getFAQ: "/faq-list",
  createFAQ: "/create-faq",
  updateFAQ: "/update-faq",
  updateFAQOrder: "/update-faq-order",
  deleteFAQ: "/delete-faq/",
  faqCategory: "/faq-categories-list",
  getTerms: "/privacy-policy/0",
  updateTerms: "/update-privacy-policy/0",
  getPrivacy: "/privacy-policy/1",
  updatePrivacy: "/update-privacy-policy/1",
  getHomeGuide: "/privacy-policy/2",
  updateHomeGuide: "/update-privacy-policy/2",
  changePassword: "/change-password-admin",
  getWritingTips: "/writing-tips-list",
  createWritingTips: "/create-writing-tip",
  updateWritingTips: "/update-writing-tip",
  updateWritingTipsOrder: "/update-writing-tip-order",
  deleteTips: "/delete-writing-tip/",
  artList: "/arts-list",
  createArt: "/create-art",
  viewArt: "/view-art/",
  updateArt: "/update-art/",
  deleteArt: "/delete-art/",
  approveArt: "/arts/",
  reportList: "/reports",
  getTransactionList: "/transactions-list",
  getPost: "/posts",
  deletePost: "/delete-post/",
  approvePost: "/approve-post/",
  contest: "/create-contest",
  contestList: "/get-ongoing-contests",
  selectWinner: "/contests/participants/",
  completedList: "/get-completed-contests",
  dashboard: "/dashboard-stats",
  getGenres: "/genres/get-genres",
  createGenres: "/genres/create-genre",
  deleteGenres: "/genres/delete-genre/",
  getTestimonial: "/testimonials-list",
  createTestimonial: "/create-testimonial",
  deleteTestimonial: "/delete-testimonial/",
  updateTestimonial: "/update-testimonial/",
  makeFeatureWriter: "/make-user-featured",
  removeFeatureWriter: "/make-user-unfeatured",
  makeArtFeature: "/make-art-featured",
  removeArtFeature: "/make-art-unfeatured",
  newsLetter: "/newsletters-list",
  contactus: "/contacts/list",
  getGenre: "/genres/genres-list",
  getAnnouncement: "/announcements-list",
  createAnnouncement: "/create-announcement",
  deleteAnnouncement: "/delete-announcement/",
  sendAnnouncement: "/send-announcement/",
  getAnnouncementDetail: "/announcement-details/",
  getPaidArt: "/paid/art/lists",
  addPaidArt: "/paid/art/store",
  updatePaidArt: "/paid/art/update",
  showPaidArtDetail: "/paid/art/show/",
  deletePaidArt: "/paid/art/delete/",
  getFeatureArtists: "/user/get-featured-artists",
  updateUserOrder: "/update-users-order",
  reportedUser: "/reports-user",
  getArtistsList: "/get-artists",
  getWriterList: "/get-writers",
  registerArtist: "/register-artist",
  deleteUser: "/delete-user/",
  makePostPublic: "/make-public/",
  makePostNotPublic: "/make-unpublic/",
  updateArt: "/update-art/",
  updateEmail: "/update-user-email/",
  getFeatureWriters: "/user/get-featured-writers",
  deleteContest: "/delete-contest/",
  unhideContest: "/unhide-context/",
  updateContest: "/update-contest/",
  pendingContest: "/get-pending-contests",
  getFeatureArts: "/get-featured-arts",
  updateFeatureArtOrder: "/update-featured-art-order",
  createBlob: "/make-blob-file",
  getGuestStories: "/guest/stories",
  updateDescription: "/add-contest-deadline-description",
  getContestDescription: "/get-contest-deadline-description",
  getAllExternalStories: "/guest/all-stories",
  deleteGuestStories: "/guest/delete-story/",
};

export { Endpoints };
