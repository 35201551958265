import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import AddContest from "../../shared/components/Modals/AddContest";
import Submissions from "../../shared/components/Modals/Submissions";
import ViewArtDetail from "./../../shared/components/Modals/ViewDetailArt";

import moment from "moment";
import { NoData } from "../../assets";
import BasicPopover from "../../shared/components/BasicPopOver";
import Loader from "../../shared/components/Loader";
import Confirmation from "../../shared/components/Modals/Confirmation";
import Pagination from "../../shared/components/Pagination";
import { toastMessage } from "../../shared/components/Toast";
import {
  contestList,
  deleteContestService,
} from "../../shared/services/contestServices";

export default function BasicTable() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [selectedArt, setSelectedArt] = useState({});
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [loader, setLoader] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [check, setCheck] = useState("");
  const [selectedContest, setSelectedContest] = useState(null);
  const [openContest, setOpenContest] = useState(false);

  const clickOpenContest = (item) => {
    setOpenContest(true);
    setSelectedContest(item);
  };

  const clickCloseContest = () => {
    setOpenContest(false);
  };

  const handleConfirmOpen = (check, item) => {
    setShowConfirm(true);
    // setSelectedArt(item);
    setCheck(check);
    setSelectedContest(item);
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  const handleOpenView = (item) => {
    setShow(true);
    setSelectedArt(item?.art);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const handleSubOpen = (item) => {
    setSubOpen(true);
    setSelectedArt(item?.art);
    let participants = [];
    item?.on_going_contest_participants?.map((participant) => {
      if (participant?.post) {
        let temp = { ...participant };
        temp["loader"] = false;
        participants?.push(temp);
      }
    });
    setSelectedParticipants(participants);
  };

  const handleSubClose = () => {
    setSubOpen(false);
  };

  const getOngoingContest = (noLoader) => {
    if (noLoader) {
      setLoader(false);
    }
    if (active == 1) {
      setLoader(true);
    }
    contestList(active)
      .then(({ data } = data) => {
        setLoader(false);
        setData(data?.data?.contests?.data);
        setLastPage(data?.data?.contests?.last_page);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getOngoingContest();
  }, [active]);

  const handleConfirmation = () => {
    deleteContestService(selectedContest?.id)
      .then(({ data }) => {
        toastMessage(data?.message);
        getOngoingContest("noLoader");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUpdate = (check) => {
    getOngoingContest("noLoader");
  };

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Ongoing Contest
      </Typography>
      {/* <input
        id="outlined-basic"
        variant="outlined"
        className="inputSearch"
        placeholder="Search"
        // onChange={(e) => setSearchText(e.target.value)}
        // value={searchText}
        autoComplete="off"
        style={{ marginBottom: '3%' }}
      /> */}
      <br />
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : data.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Art </TableCell>
                <TableCell>Contest End</TableCell>
                <TableCell>Instructions</TableCell>
                <TableCell>Art detail</TableCell>
                <TableCell>Submissions</TableCell>
                <TableCell>Delete</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, inx) => (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{inx + 1}</TableCell>
                  <TableCell>
                    <Box className="tableBox">
                      {item?.art?.title ? item?.art?.title : "Not Provided"}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {moment(item?.end_date).format(" Do MMMM YYYY")}
                  </TableCell>
                  <TableCell>
                    <BasicPopover
                      reason={
                        item?.instructions ? item?.instructions : "Not Provided"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleOpenView(item)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#50934B",
                        color: "white",
                        "&:hover": {
                          background: "#50934B",
                        },
                      }}
                      onClick={() => handleSubOpen(item)}
                    >
                      <FolderOpenIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        background: " rgba(255, 183, 190, 0.9);",
                        marginLeft: "5%",
                      }}
                      onClick={(e) => handleConfirmOpen("Delete", item)}
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        background: "#acc8cc",
                        marginLeft: "5%",
                      }}
                      // onClick={(e) => handleConfirmOpen("Delete", item)}
                      onClick={() => clickOpenContest(item)}
                    >
                      <ModeEditIcon sx={{ color: "white" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {lastPage > 1 && !loader ? (
        <Pagination lastPage={lastPage} active={active} setActive={setActive} />
      ) : (
        ""
      )}
      <ViewArtDetail
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        check={"Art"}
      />
      <Submissions
        open={subOpen}
        handleClickOpen={handleSubOpen}
        handleClose={handleSubClose}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        selectedParticipants={selectedParticipants}
        setSelectedParticipants={setSelectedParticipants}
      />
      <AddContest
        open={openContest}
        handleClickOpen={clickOpenContest}
        handleClose={clickCloseContest}
        selectedArt={selectedArt}
        update
        selectedContest={selectedContest}
        handleUpdate={handleUpdate}
      />
      <Confirmation
        open={showConfirm}
        handleClickOpen={handleConfirmOpen}
        handleClose={handleConfirmClose}
        check={check}
        setCheck={setCheck}
        handleConfirmation={handleConfirmation}
        selectedArt={selectedArt}
      />
    </Box>
  );
}
