import CloseIcon from "@mui/icons-material/Close";
import { Box, Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../components/Toast";
import { startContest, updateContest } from "../../services/contestServices";
import Loader from "../Loader";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};

const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "image",
  "video",
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
  selectedArt,
  update,
  selectedContest,
  handleUpdate,
}) {
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const [instructions, setInstructions] = useState("");

  const handleSubmit = () => {
    let obj = {
      art_id: selectedArt?.id,
      end_date: moment(moment(date).toISOString()).format("yyyy-MM-D hh:mm:ss"),
      is_active: 1,
      instructions: instructions,
    };

    startContest(obj)
      .then(({ data } = data) => {
        navigate("/ongoingContest");
        toastMessage(data?.message);
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, "error");
      });
  };

  const onClose = () => {
    setInstructions("");
    setDate(null);
    handleClose();
  };

  const handleSubmitUpdate = () => {
    let obj = {
      end_date: moment(date).format("yyyy-MM-D"),
      instructions: instructions,
    };

    updateContest(obj, selectedContest?.id)
      .then(({ data }) => {
        toastMessage(data?.message);
        onClose();
        handleUpdate();
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, "error");
      });
  };

  function handleTextChange(value) {
    setInstructions(value);
  }

  useEffect(() => {
    if (selectedContest) {
      setDate(new Date(selectedContest?.end_date));
      setInstructions(selectedContest?.instructions);
    }
  }, [selectedContest]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              {update ? "Update" : "Start"} a contest !
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              minHeight: (theme) => theme.spacing(45),
            }}
          >
            {/* <DialogContentText id="alert-dialog-slide-description"> */}
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '19px',
                    width: '200px',
                  }}
                >
                  Start Date:
                </Typography>
                <CustomDatePicker date={date} setDate={setDate} />
              </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5%",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "19px",
                  width: "200px",
                }}
              >
                End Date:
              </Typography>
              <CustomDatePicker date={date} setDate={setDate} />{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5%",
                minHeight: (theme) => theme.spacing(20),
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "19px",
                  width: "200px",
                }}
              >
                Instructions
              </Typography>
              <ReactQuill
                value={instructions}
                onChange={handleTextChange}
                modules={modules}
                formats={formats}
              />
              {/* <textarea className="instructions" value={instructions} onChange={(e) => setInstructions(e.target.value)}>

                </textarea> */}
            </Box>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button
              onClick={onClose}
              variant="contained"
              className="customButton2"
            >
              Close
            </Button>
            <Button
              variant="contained"
              className="customButton"
              onClick={
                !update ? () => handleSubmit() : () => handleSubmitUpdate()
              }
            >
              {loader ? <Loader /> : update ? "Update" : "Start"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}

const CustomDatePicker = ({ date, setDate }) => {
  return (
    <DatePicker
      selected={date}
      onChange={(date) => setDate(date)}
      minDate={new Date()}
      dateFormat="dd/MM/yyyy"
    />
  );
};
