import {
  Paper,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Container,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NoData, PlaceholderImage } from "../../assets";
import {
  getAllPaidArt,
  getPaidArtDetail,
  createPaidArt,
  updatePaidArt,
  deletePaidArt,
} from "./../../shared/services/PaidArtService";
import Loader from "../../shared/components/Loader";
import Confirmation from "../../shared/components/Modals/Confirmation";
import { toastMessage } from "../../shared/components/Toast";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import Pagination from "../../shared/components/Pagination";

function PaidWork() {
  const [loader, setLoader] = useState(false);
  const [allPaidArt, setAllPaidArt] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedArt, setSelectedArt] = useState(null);
  const [update, setUpdate] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const handleShow = (item) => {
    setShowConfirm(true);
    if (item) {
      setSelectedArt(item);
    }
  };

  const handleCloseShow = () => {
    setShowConfirm(false);
    setSelectedArt(null);
  };

  const handleOpen = (item, check) => {
    setSelectedArt(item);
    setOpen(true);
    if (check) {
      setUpdate(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArt(null);
  };

  const handleOpenDetail = (item) => {
    setSelectedArt(item);
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const getPaidArt = () => {
    if (active == 1) {
      setLoader(true);
    }

    getAllPaidArt(active)
      .then(({ data: { data } }) => {
        setAllPaidArt(data?.data);
        setLoader(false);
        setLastPage(data?.meta?.last_page);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getPaidArt();
  }, [active]);

  const handleConfirmation = (check) => {
    if (check == "Delete") {
      deletePaidArt(selectedArt?.id)
        .then(({ data: { data, message } }) => {
          toastMessage(message, "success");
          setSelectedArt({});
          setAllPaidArt(
            allPaidArt.filter((item) => item.id !== selectedArt?.id)
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleAdd = (obj, check) => {
    if (check) {
      updatePaidArt(obj)
        .then(({ data: { data, message } }) => {
          console.log("update", data);
          toastMessage(message, "success");
          handleClose();
          setAllPaidArt((prevArr) =>
            prevArr.map((item) => (item.id === data.id ? data : item))
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      createPaidArt(obj)
        .then(({ data: { data, message } }) => {
          toastMessage(message, "success");
          handleClose();
          setAllPaidArt([...allPaidArt, data]);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <Box
      sx={{
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "24px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
              marginBottom: "2%",
            }}
          >
            Store{" "}
          </Typography>
        </Box>
        <Box>
          {allPaidArt?.length >= 3 ? null : (
            <Button
              sx={{
                width: "100%",
                background: "#101828",
                border: "1px solid #101828",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                // borderRadius: '24px',
                "&:hover": {
                  backgroundColor: "#000000",
                },
              }}
              variant="contained"
              onClick={() => handleOpen()}
            >
              Add More
            </Button>
          )}
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", marginTop: "5%" }}
      >
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : allPaidArt.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Discounted Price</TableCell>
                <TableCell>Detail</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPaidArt?.map((item, inx) => (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {inx + 1}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>$ {item.flat_fee}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleOpenDetail(item)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        background: "#B3B3B3",
                        marginLeft: "5%",
                        "&:hover": {
                          background: "#dcdbdb",
                        },
                      }}
                      onClick={() => handleOpen(item, true)}
                    >
                      <EditIcon sx={{ color: "white" }} />
                    </Button>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Button
                      sx={{
                        background: " rgba(255, 183, 190, 0.9);",
                        marginLeft: "5%",
                      }}
                      onClick={() => handleShow(item)}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {!loader && lastPage > 1 ? (
        <Pagination lastPage={lastPage} active={active} setActive={setActive} />
      ) : (
        ""
      )}

      <Confirmation
        open={showConfirm}
        handleClickOpen={handleShow}
        handleClose={handleCloseShow}
        check={"Delete"}
        handleConfirmation={handleConfirmation}
      />
      {open && (
        <AddPaidWork
          open={open}
          handleClickOpen={handleOpen}
          handleClose={handleClose}
          handleAdd={handleAdd}
          loader={loader}
          selectedArt={selectedArt}
          update={update}
          setUpdate={setUpdate}
          setSelectedArt={setSelectedArt}
        />
      )}
      <DetailModal
        open={openDetail}
        handleClickOpen={handleOpenDetail}
        handleClose={handleCloseDetail}
        selectedArt={selectedArt}
        setSelectedArt={setSelectedArt}
      />
    </Box>
  );
}

export default PaidWork;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddPaidWork({
  open,
  handleClickOpen,
  handleClose,
  handleAdd,
  loader,
  selectedArt,
  setSelectedArt,
  update,
  setUpdate,
}) {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(1);
  const [paymentLink, setPaymentLink] = useState("");
  const [discount, setDiscount] = useState(0);
  const [selectedImage, setSelectedImage] = useState({
    preview: "",
    raw: "",
  });

  const handleCreate = () => {
    console.log();
    if (
      title.length == 0 ||
      paymentLink.length == 0 ||
      selectedImage.preview == ""
    ) {
      toastMessage("Please enter all fields", "error");
    } else {
      let obj = {
        title: title,
        status: 1,
        price: price,
        discount: discount,
        payment_link: paymentLink,
      };

      if (selectedImage.raw) {
        obj["image"] = selectedImage.raw;
      }

      if (update) {
        obj["id"] = selectedArt?.id;
      }

      handleAdd(obj, update);
      if (!loader) {
        onClose();
      }
    }
  };

  const handleChange = (event, check) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");

    let validatedValue = sanitizedValue;

    if (sanitizedValue !== "") {
      const numericValue = parseInt(sanitizedValue, 10);
      if (check === "Price") {
        validatedValue = Math.max(1, numericValue).toString();
      } else {
        validatedValue = numericValue.toString();
      }
    }

    if (check === "Price") {
      setPrice(validatedValue);
    } else {
      setDiscount(validatedValue);
    }
  };

  const onClose = () => {
    setTitle(null);
    setPrice(1);
    setDiscount(0);
    setSelectedArt(null);
    setSelectedImage({
      preview: "",
      raw: "",
    });
    setPaymentLink("");
    setUpdate(false);
    handleClose();
  };

  const theme = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2%",
  };

  const endTheme = {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    flexDirection: "column",
    alignItems: "end",
  };

  const w100 = {
    width: "100%",
  };

  useEffect(() => {
    if (selectedArt) {
      let { title, discount, price, payment_link, full_image_path } =
        selectedArt;
      setTitle(title);
      setDiscount(discount);
      setPrice(price);
      setPaymentLink(payment_link);
      setSelectedImage({
        preview: full_image_path,
      });
    }
  }, [selectedArt]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              Add paid artwork
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div>
                Title:
                <input
                  className="inputFAQ"
                  style={{ fontWeight: "600" }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div style={theme}>
                <div style={w100}>
                  Price:
                  <input
                    type="number"
                    className="inputFAQ"
                    style={{ width: "95%" }}
                    value={price}
                    onChange={(e) => handleChange(e, "Price")}
                  />
                </div>
                <div style={endTheme}>
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    {" "}
                    Discount (%):
                  </div>
                  <input
                    type="number"
                    className="inputFAQ"
                    style={{ width: "95%" }}
                    value={discount}
                    onChange={(e) => handleChange(e, "discount")}
                  />
                </div>
              </div>
              <div style={{ marginTop: "2%" }}>
                Payment link:
                <input
                  className="inputFAQ"
                  style={{ fontWeight: "600" }}
                  value={paymentLink}
                  onChange={(e) => setPaymentLink(e.target.value)}
                />
              </div>
              <ImageUploader
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </DialogContentText>
            <br />
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button
              onClick={onClose}
              variant="contained"
              className="customButton2"
            >
              Close
            </Button>
            <Button
              variant="contained"
              className="customButton"
              onClick={() => handleCreate()}
            >
              {loader ? <Loader /> : update ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}

const ImageUploader = ({ selectedImage, setSelectedImage }) => {
  const fileInputRef = useRef(null);

  const allowedFileTypes = ["image/svg+xml", "image/png", "image/jpeg"];

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file && allowedFileTypes.includes(file.type)) {
      setSelectedImage({
        preview: URL.createObjectURL(file),
        raw: file,
      });
    } else {
      alert("Invalid file format! Please select an SVG, PNG, or JPEG file.");
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage({
      preview: "",
      raw: "",
    });
  };

  return (
    <div>
      <input
        type="file"
        accept=".svg, .png, .jpeg, .jpg"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
      {!selectedImage.preview && (
        <div onClick={handleButtonClick} className="uploadPaidImage">
          <div>Selected Image aspect ratio should be 336x428.</div>
          <div>Excess content would be cropped out.</div>
        </div>
      )}{" "}
      {selectedImage.preview && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={selectedImage.preview}
              alt="Selected"
              onClick={handleButtonClick}
              className="uploadedPaidImage"
            />
          </div>
        </>
      )}
    </div>
  );
};

function DetailModal({
  open,
  handleClickOpen,
  handleClose,
  setSelectedArt,
  selectedArt,
}) {
  const onClose = () => {
    handleClose();
    setSelectedArt(null);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              Paid artwork detail
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="ArtImageDetail"
                  src={selectedArt?.full_image_path}
                />
              </Box>
              <DetailSection heading={"Title"} detail={selectedArt?.title} />
              <DetailSection
                heading={"Full price"}
                detail={selectedArt?.price}
              />
              <DetailSection
                heading={"Discount"}
                detail={selectedArt?.discount + "%"}
              />
              <DetailSection
                heading={"Payment Link"}
                detail={selectedArt?.payment_link}
              />
              <DetailSection
                heading={"Created at"}
                detail={moment(selectedArt?.created_at).format(" Do MMMM YYYY")}
              />
            </DialogContentText>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}

const DetailSection = ({ heading, detail }) => {
  return (
    <div className="detailContainer">
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "19px",
          marginBottom: "2%",
        }}
      >
        {heading}
      </Typography>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "left",
          color: "#808191",
        }}
      >
        {detail}
      </Typography>
    </div>
  );
};
