import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Loader from "./../Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { changePasswordService } from "../../services/authService";
import { toastMessage } from "./../Toast";
import CloseIcon from "@mui/icons-material/Close";
import { DialogActions } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClickOpen, handleClose, selectedDescription, setSelectedDescription }) {
  const onClose = () => {
    handleClose();
    setSelectedDescription("");
  };
  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" className="dialogContainer">
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
            }}
            color="text.secondary"
          >
            Description:
            <p dangerouslySetInnerHTML={{ __html: selectedDescription }} className="dangerousHTML" style={{ marginTop: "5%", marginBottom: "5%" }}></p>
          </DialogTitle>
          <DialogActions
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button onClick={onClose} variant="contained" className="customButton2">
              Close
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
