import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Container } from '@mui/material';
import { registerUserService, updateEmail } from "../../services/userService"
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toastMessage } from '../Toast';
import Loader from '../Loader';
import { Placeholder, PlaceholderImage } from '../../../assets';
import DeleteIcon from '@mui/icons-material/Delete';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClickOpen, handleClose, handleAdd, setSelectedUser, selectedUser, setEditBool, editBool, getUsers }) {
  const [image, setImage] = useState({ raw: '', preview: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [laoder, setLoader] = useState(false)

  const handleSubmit = () => {
    if (editBool) {
      if (email.length == 0) {
        toastMessage("Please enter email to update", "error")
      } else {
        setLoader(true)
        updateEmail({ email: email }, selectedUser?.id)
          .then(({ data }) => {
            onClose();
            getUsers();
            // toastMessage(data?.data)
            setLoader(false)
            toastMessage(data?.message)
          })
          .catch(() => {
            setLoader(false)
          })
      }
    } else {
      if (fullName == "" || username == "") {
        toastMessage("Please enter all enteries", "error")
      } else {
        setLoader(true)
        let obj = {
          name: fullName,
          user_name: username,
          // password,
          // password_confirmation: confirmPassword
        }
        if (email) {
          obj['email'] = email
        }

        if (image?.preview) {
          obj['profile_pic'] = image?.raw
        }

        registerUserService(obj)
          .then(({ data: { data: { user }, message } }) => {
            handleAdd(user)
            setLoader(false)
            onClose();
            toastMessage(message)
          })
          .catch((err) => {
            toastMessage(err?.response?.data?.message, "error")
            setLoader(false)
          })
      }
    }
  }


  const onClose = () => {
    handleClose();
    setFullName("");
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setUserName("");
    setSelectedUser(null);
    setEditBool(false);
    setImage({ raw: '', preview: '' })
  }

  useEffect(() => {
    if (selectedUser) {
      setUserName(selectedUser?.user_name)
      setFullName(selectedUser?.name)
      setEmail(selectedUser?.email)
      if (selectedUser?.profile_pic) {
        setImage({ preview: selectedUser?.full_profile_path, raw: "" })
      }
    }
  }, [selectedUser, open])


  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogContent sx={{ padding: "0px" }}>
            <h1>
              {editBool ? "Edit Artist" : "Add Artist"}
            </h1>
            {editBool ? null : <ImageUpload image={image} setImage={setImage} />}
            <input placeholder="Enter full name" className="signUpInput" value={fullName} onChange={(e) => setFullName(e.target.value)} disabled={editBool} />
            <input placeholder='Enter email (optional)' className="signUpInput" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input placeholder="Enter username" className="signUpInput" value={username} onChange={(e) => setUserName(e.target.value)} disabled={editBool} />
            {/* <div style={{ position: "relative" }}>
              <input placeholder='Enter password' className="signUpInput" type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
              {showPassword ? <VisibilityIcon sx={{ position: "absolute", top: "8px", right: "8px" }} onClick={() => setShowPassword(!showPassword)} /> :
                <VisibilityOffIcon sx={{ position: "absolute", top: "8px", right: "8px" }} onClick={() => setShowPassword(!showPassword)} />}
            </div>
            <div style={{ position: "relative" }}>
              <input placeholder='Enter confirm password' className="signUpInput" type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              {showConfirmPassword ? <VisibilityIcon sx={{ position: "absolute", top: "8px", right: "8px" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                <VisibilityOffIcon sx={{ position: "absolute", top: "8px", right: "8px" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
            </div> */}
            <button className='addArtistButton' onClick={handleSubmit}>
              {laoder ? <Loader /> : editBool ? "Update" : "Add"}
            </button>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}



const ImageUpload = ({ image, setImage }) => {

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage({
          raw: file,
          preview: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="imageInput"
        style={{ display: 'none' }}
        onChange={handleImageChange}
        multiple
      />

      {image.preview ? (
        <div className="uploadImageArtist">
          <DeleteIcon sx={{ color: "red", position: "absolute", top: "0px", right: "0px", background: "white", cusror: "pointer" }} onClick={() => setImage({ raw: '', preview: '' })} />
          <img src={image.preview} alt="Preview" style={{ width: "fit-content" }} height={100} />
        </div>
      ) : <div style={{ display: 'flex', marginTop: '20px', justifyContent: "center", marginBottom: "20px", cusror: "pointer" }}>
        <label htmlFor="imageInput" >
          <img src={PlaceholderImage} height={100} style={{ width: "fit-content" }} />
        </label>
      </div>}
    </div>
  );
};

