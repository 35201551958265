import './App.css';
import Login from "./pages/auth/Login"
import AllRoutes from './routes/routes'
import { ToastContainer } from 'react-toastify'


function App() {
  return (
    <div className="hide-scrollbar">
      <AllRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
