import CloseIcon from "@mui/icons-material/Close";
import { Box, Container, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { PlaceholderImage } from "../../../assets";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
  selectedUser,
  setSelectedUser,
}) {
  const onClose = () => {
    handleClose();
    setSelectedUser({});
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
                letterSpacing: "-0.5px",
                color: "#11142D",
              }}
              color="text.secondary"
            >
              View detail !
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  selectedUser?.profile_pic
                    ? selectedUser?.full_profile_path
                    : PlaceholderImage
                }
                style={{ height: "200px", width: "auto" }}
              />
            </Box>
            <DetailSection heading="Name" detail={selectedUser?.name} />
            <DetailSection heading="Email" detail={selectedUser?.email} />
            <DetailSection
              heading="Status"
              detail={selectedUser?.is_blocked ? "Blocked" : "Active"}
            />
            <DetailSection
              heading="Platform"
              detail={
                selectedUser?.social_network == 1
                  ? "Facebook"
                  : selectedUser?.social_network == 2
                  ? "Twitter"
                  : "via email"
              }
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose}>Agree</Button> */}
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}

const DetailSection = ({ heading, detail }) => {
  return (
    <div className="flexContainer detailContainer flexModal">
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "19px",
        }}
      >
        {heading}
      </Typography>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "right",
          color: "#808191",
        }}
      >
        {detail}
      </Typography>
    </div>
  );
};
