import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const getPostsServices = (paginate, searchText) => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.getPost + `/10?page=${paginate}&search=${searchText}`
  );
};

const getAllPostsServices = () => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.getPost + `/100000?page=${1}&search=`
  );
};

// const createArtServices = (obj) => {
//   let formBody = new FormData();
//   Object.keys(obj).forEach((key) => {
//     formBody.append(key, obj[key]);
//   });
//   return HTTP_Request.post(BASE_URL + Endpoints.createArt, formBody);
// }

// const viewArtService = () => {
//   return HTTP_Request.get(BASE_URL + Endpoints.viewArt);
// }

// const updateArtSerives = (obj) => {
//   let formBody = new FormData();
//   Object.keys(obj).forEach((key) => {
//     formBody.append(key, obj[key]);
//   });
//   return HTTP_Request.post(BASE_URL + Endpoints.updateArt, formBody);
// }

const deletePostServices = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deletePost + id);
};

const approvePostServices = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.approvePost + id);
};

// const ReportPostServices = (entity, search) => {
//   return HTTP_Request.get(BASE_URL + Endpoints.reportList + `?entity_type=${entity}&search=${search}`);
// }

const makeFeatureWriter = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.makeFeatureWriter, obj);
};

const removeFeatureWriter = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.removeFeatureWriter, obj);
};

const getAllFeatureArtists = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getFeatureArtists);
};

const getAllFeatureWriters = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getFeatureWriters);
};

const makePostPublic = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.makePostPublic + id);
};

const makePostUnPublic = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.makePostNotPublic + id);
};

const getAllGuestStories = (active) => {
  return HTTP_Request.get(
    BASE_URL + Endpoints.getGuestStories + `?page=${active}`
  );
};

const getAllExternalStories = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getAllExternalStories);
};

const deleteGuestStories = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteGuestStories  + id);
};

export {
  getPostsServices,
  deletePostServices,
  approvePostServices,
  makeFeatureWriter,
  removeFeatureWriter,
  getAllFeatureArtists,
  makePostPublic,
  makePostUnPublic,
  getAllFeatureWriters,
  getAllGuestStories,
  getAllPostsServices,
  getAllExternalStories,
  deleteGuestStories
};
