import React, { useEffect } from 'react'
import Drawer from './../shared/components/Drawer'
import Menu from './../shared/components/Menu'

export default function Layout(props) {
  const changeTitle = () => {
    document.title = props.title + ' | JMK'
  }
  useEffect(() => {
    changeTitle()
  })
  return (
    <>
      <div>
        <Menu />
        <Drawer {...props} />
      </div>
    </>
  )
}
