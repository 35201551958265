import React, { useState, useEffect } from "react";
import Table from "./../../shared/components/Table";
import Data from "./../../shared/utils/fakeData.json";
import Confirmation from "./../../shared/components/Modals/Confirmation";
import ViewDetail from "./../../shared/components/Modals/ViewDetail";
import { TextField, Typography } from "@mui/material";
import { userService, blockService, unBlockService } from "../../shared/services/userService";
import Pagination from "../../shared/components/Pagination";

function Users() {
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState("");
  const [openView, setOpenView] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const [selectedUser, setSelectedUser] = useState({});
  let tablularHead = [
    "#",
    "Name",
    "Email",
    "Platform",
    "Details",
    // "Delete User",
    "Status",
  ];

  const handleClickShow = (check, item) => {
    setShow(true);
    setCheck(check);
    setSelectedUser(item);
  };

  const handleCloseShow = () => {
    setShow(false);
    // setSelectedUser({})
    setCheck("");
  };

  const handleOpenView = (item) => {
    setOpenView(true);
    setSelectedUser(item);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const getUsers = () => {
    let obj = {
      page: active,
      pagination: 10,
      search_text: searchText,
      filter: "blocked",
    };

    if (active == 1) {
      setLoader(true);
    }
    userService(obj, active)
      .then(({ data } = data) => {
        setLastPage(data?.data?.userList?.last_page);
        setUsers(data.data.userList.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, [searchText, active]);

  const handleConfirmation = (resp, selectedUser) => {
    setStatusLoader(true);
    if (resp == "Block") {
      blockService({ id: selectedUser?.id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = true;
          }
          setUsers(temp);
          setStatusLoader(false);
        })
        .catch((err) => {
          console.log("err", err);
          setStatusLoader(false);
        });
    } else if (resp == "Unblock") {
      unBlockService({ id: selectedUser?.id })
        .then(({ data } = data) => {
          let temp = [...users];
          let index = temp.findIndex((i) => i.id == data?.data?.user?.id);
          if (index > -1) {
            temp[index]["is_blocked"] = false;
            temp.splice(index, 1);
          }
          setUsers(temp);
          setStatusLoader(false);
        })
        .catch((err) => {
          console.log("err", err);
          setStatusLoader(false);
        });
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Blocked Users
      </Typography>
      <input id="outlined-basic" variant="outlined" className="inputSearch" placeholder="Search by name and email" onChange={(e) => setSearchText(e.target.value)} value={searchText} autoComplete="off" style={{ marginBottom: "3%" }} />
      <Table tablularHead={tablularHead} tabularArr={users} Confirmation={Confirmation} open={show} handleClickOpen={handleClickShow} handleClose={handleCloseShow} check={check} selectedUser={selectedUser} setSelectedUser={setSelectedUser} openView={openView} handleOpenView={handleOpenView} handleCloseView={handleCloseView} handleConfirmation={handleConfirmation} statusLoader={statusLoader} loader={loader} lastPage={lastPage} active={active} setActive={setActive} />

      <ViewDetail open={openView} handleClickOpen={handleOpenView} handleClose={handleCloseView} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
    </>
  );
}

export default Users;
