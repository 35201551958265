import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./../../utils/imageCropper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { createBlob } from "../../services/ArtServices";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
  file,
  setCropImage,
  handleUploadArt,
  rectangle,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState("");
  const [files, setFiles] = useState(null);
  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      if (rectangle && files) {
        let imgFile = await getCroppedImg(files, croppedAreaPixels);
        setCroppedImage(imgFile);
        setCropImage(imgFile);
      } else if (file) {
        let imgFile = await getCroppedImg(file, croppedAreaPixels);
        setCroppedImage(imgFile);
        setCropImage(imgFile);
      }
    },
    [files, file]
  );

  const handleSubmit = () => {
    handleClose();
    handleUploadArt(croppedImage);
  };

  const makeBlob = () => {
    let obj = {
      image_url: file,
    };
    createBlob(obj)
      .then(({ data }) => {
        setFiles(data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (file) {
      makeBlob();
    }
  }, [file]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer croppedDialog"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="App" id="hacks">
              <div className="crop-container">
                <Cropper
                  image={file}
                  crop={crop}
                  zoom={zoom}
                  aspect={rectangle ? 8 / 3 : 4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className="controls">
                <div>
                  <RemoveIcon sx={{ marginRight: "5px" }} />
                </div>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range"
                />
                <div>
                  <AddIcon sx={{ marginLeft: "5px" }} />
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: "white",
              border: "1px solid black",
              color: "black",
              "&:hover": {
                background: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "black",
              border: "1px solid black",
              "&:hover": {
                background: "black",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
